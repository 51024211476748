////
/// Smartguide colors
/// @group smartGuides
////

/// @access public
/// @type Color
/// @author Hatef Takyar
/// @since 02.09.2018
$defaultLineColor: #666666;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$activeColor: #61C90D;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$disabledButtonBackgroundColor: #777;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$slideShowImageBorderColor: #CCC;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$childOutlineColor: #0FC3D8;