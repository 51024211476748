.VideoObject {
  margin-top: .625rem;
  top: unset !important;
  left: unset !important;
  @media(max-width: 500px) {
    height: 38vh !important;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    height: 50vh !important;
  }

  //noinspection CssInvalidPropertyValue
  min-height: -webkit-fill-avilable;
  max-width: 100vw !important;

  .frameConsentLayer {
    //noinspection CssInvalidPropertyValue
    height: -webkit-fill-available;
    max-width: 100vw;
  }
}