@import "utilities/colors/globalColors";

////
/// Mobile
/// @group mobile
////

/// Mobile parent
.mobile {
  min-width: 300px !important;
  height: auto !important;

  /// Mobile wildcard
  * {
    min-width: 0 !important;
    max-width: 100% !important;

    form[id^="customForm"] {
      margin-left: .5em;
      margin-right: .5em;

      input[type="checkbox"],
      input[type="radio"] {
        width: 5% !important;
      }

      #gdprContainer {
        input {
          width: 10em !important;
        }
      }
    }
  }

  /// @todo Documentation needed
  .positioned {
    &, object {
      &:not(.ui):not(.dcmp-resize-overlay-base) {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
      }
    }
  }

  .HeaderLogoImageObject {
    overflow: hidden;

    --burgerSize: 2.875rem;
    --burger-margin: .625rem;

    left: calc(100% - (var(--burgerSize) - var(--burger-margin))) !important;

    --burgerCalc: calc(100% - (var(--burgerSize) + (3 * var(--burger-margin))));
    max-width: var(--burgerCalc) !important;

    &.quadratic, &.vertical {
      max-width: calc(var(--burgerCalc) - 50%) !important;
    }

    &.vertical {
      min-height: calc((3 * var(--burgerSize)) + (2 * var(--burger-margin)));
    }

    div, img {
      position: relative !important;
    }
  }

  .HeaderLogoImageObject,
  .ImageObject {
    max-height: 100% !important;
  }

  .root-area, .footer, .footer-area, #content-area, #footer, #footer-area {
    position: relative !important;
    top: 0 !important;
    height: auto !important;

    .GoogleMap,
    .PDFViewerObject {
      margin-top: 5vh !important;
      margin-bottom: 5vh !important;
    }

    .scroll-margin {
      margin-top: 25vh !important;
    }
  }

  #footer-area {
    .GoogleMap, .PDFViewerObject, .PDFViewerObject object {
      min-height: 75vh;
      max-height: 75vh;
    }
  }

  #content,
  .ContentModule .positioned:not(.TextObject):not(.ImageObject):not(.VideoObject):not(.childWrapper),
  .auto-height,
  .LanguageChangeObject ul {
    height: auto !important;
  }

  .positioned {
    &:not(.HeaderLogoImageObject):not(.ImageObject):not(.VideoObject):not(.DecorationLine):not(.BackgroundAreas):not(.GoogleMap):not(.Circle) {
      &:not(.footer):not(.footer-area):not(.root-area) .childWrapper,
      &:not(.childWrapper):not(.dcmp-resize-overlay):not(.dcmp-fake-resize-overlay):not(.ui):not(.dcmp-notification-area):not(.mobile-calc-height) {
        height: 100% !important;
      }
    }
  }

  .ImageObject, .HeaderLogoImageObject {
    .one-hundred-percent {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .positioned:not(.dcmp-resize-overlay):not(.dcmp-fake-resize-overlay):not(.ui):not(.dcmp-notification-area):not(.ImageObject):not(.VideoObject):not(.TextObject) {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
  }

  .ContentModule {
    padding: 10px;
    text-align: center;
  }

  .dcmp-mobile-invisible,
  .MenuItemsObject {
    display: none !important;
  }

  .childWrapper {
    padding: 10px;
  }

  .GoogleMap {
    height: 75vh !important;
  }

  .PDFViewerObject, .PDFViewerObject object {
    max-height: 75vh;
  }

  .SocialMediaObject a,
  .ContentModule .childWrapper > div {
    float: none !important;
    display: inline-block;
  }

  .positioned:not(:first-child):not(.dcmp-resize-overlay):not(.dcmp-fake-resize-overlay):not(.ui):not(.dcmp-notification-area), .footer {
    margin-top: 10px !important;
  }

  .root-area, .root-area > :first-child, .footer > :first-child {
    margin-top: 0 !important;
  }

  .mobile-full-width, .HeaderLogoObject, .TextObject {
    width: 100% !important;
  }

  .mobile-left, .mobile-left > p, .mobile-left > p span {
    margin-left: 0 !important;
    text-align: left !important;
  }

  .mobile-center, .mobile-center > p, .mobile-center > p span {
    margin: 0 auto !important;
    text-align: center !important;
  }

  .mobile-right, .mobile-right > p, .mobile-right > p span {
    margin-left: auto !important;
    margin-right: 0 !important;
    text-align: right !important;
  }

  .gallery {
    .no-margin-left {
      margin-left: 0 !important;
    }

    .no-margin-right {
      margin-right: 0 !important;
    }
  }

  .color-black {
    color: var(--black) !important;
  }

  .color-white {
    color: $white !important;
  }

  .HtmlScriptObject iframe {
    // I think the below line must have a reason to be, but whatever it is , makes problem for responsive mobile iframe widgets
    // so I would deactivate it and when something wrong happens, it must be fixed in a better way! ( not with a fixed number for height )
    // height: 75vh !important;
    // it is changed to auto
    height: auto;
  }

  .HeaderLogoImageObject:not(.vw66):not(.vw60):not(.vw50):not(.vw40):not(.vw30):not(.vw25) {
    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  .TextObject {
    ul, ol {
      li {
        text-align: left !important;
        margin-left: 15px;
      }
    }
  }

  /// Sidenav
  @import "mobile-partials/sidenav";

  /// burger menu
  .burger {
    display: block;
  }

  #content, #content-area {
    z-index: 2500;
  }

  #footer, #footer-area, .published {
    z-index: 2000;
  }

  #content, #content-area, #footer, #footer-area, .published {
    min-width: 0 !important;
    min-height: 0;
    max-width: 100% !important;
  }

  #content-area, #footer-area {
    & > .TextObject {
      &.dcmp-full-width, &.mobile-full-width {
        padding: 0 10px;
      }
    }
  }

  #content #content-area, #footer #footer-area {
    & > *:not(.header-box) {
      top: 10px !important;
    }
  }

  #footer {
    margin-top: 10px !important;
  }

  .fullscreen-bg img {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100vw !important;
    max-height: 100%;
    margin: auto;
    overflow: auto;
  }

  /// anchor elements
  .a-class {
    cursor: default;
    -webkit-touch-callout: none; /* iOS Safari */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }

  table:not(.ui-datepicker-calendar) tr td {
    width: 100% !important;
    float: left;
    display: block;
  }

  .LanguageChangeObject {
    width: 100% !important;

    ul {
      text-align: right !important;
      padding-left: 10px !important;
      height: auto !important;

      li {
        height: auto !important;
        width: auto !important;
        margin-right: 10px !important;

        a {
          width: var(--lco-height) !important;
          height: var(--lco-height) !important;
        }
      }
    }
  }

  .ImageObject div, .ImageObject img, .ImageObject a, .slider {
    position: relative !important;
  }

  @import "./mobile-partials/header";
}

.VideoObject {
  &.positioned.mobile-full-width {
    overflow: visible !important;
  }
}