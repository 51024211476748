form[id^="customForm"] {
  label,
  legend {
    display: inline-block;
    border: none;
    border-width: 0 !important;
  }

  input {
    &::-webkit-file-upload-button {
      cursor: pointer;
      background-color: transparent;
      border: 0;
      -webkit-appearance: none;
      float: right;
      color: unset;
      font-family: unset;
      font-weight: unset;
      font-size: unset;
    }

    &[type="checkbox"] {
      -webkit-appearance: checkbox !important;
      -moz-appearance: checkbox !important;
    }

    &[type="text"],
    &[type="number"],
    &[type="email"],
    &[type="tel"],
    &[type="date"],
    &[type="time"] {
      width: 100%;
    }
  }

  fieldset select,
  fieldset input,
  .field textarea {
    width: 100%;
  }

  .field {
    input[type="checkbox"] {
      &[data-single-checkbox='true'] {
        width: 2%;
      }

      &:not([data-single-checkbox="true"]) {
        width: 2%;
      }
    }

    label {
      width: 90%;
    }
  }

  fieldset {
    label {
      width: 90%;
    }

    input[type="radio"] {
      width: 2%;
    }
  }

  button {
    width: 50% !important;
    margin-left: 25%;
    margin-right: 25%;
  }

  textarea {
    resize: none;
  }

  label,
  legend {
    display: inline-block;
    border: none;

    &[data-required="true"]::after {
      content: " *";
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDA1LjQ1NiA0MDUuNDU2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0MDUuNDU2IDQwNS40NTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4NCjxnPg0KCTxwYXRoIGQ9Ik03NC4xMzQsNjQuMTQ3Yy00Ljk4NSwwLjA3OC05LjkxMSwyLjE2My0xMy40MzgsNS42ODhsLTU1LDU1QzIuMDk2LDEyOC40MzIsMCwxMzMuNDkyLDAsMTM4LjU4MyAgIHMyLjA5NiwxMC4xNTEsNS42OTcsMTMuNzVsMTgzLjI4MSwxODMuMjgxYzMuNTk5LDMuNjAxLDguNjU5LDUuNjk3LDEzLjc1LDUuNjk3czEwLjE1MS0yLjA5NiwxMy43NS01LjY5N2wxODMuMjgxLTE4My4yODEgICBjMy42MDEtMy41OTksNS42OTctOC42NTksNS42OTctMTMuNzVzLTIuMDk2LTEwLjE1MS01LjY5Ny0xMy43NWwtNTUtNTVjLTMuNTk4LTMuNTkxLTguNjUxLTUuNjgxLTEzLjczNC01LjY4MSAgIGMtNS4wODMsMC0xMC4xMzYsMi4wOS0xMy43MzQsNS42ODFMMjAyLjcyOCwxODQuMzk3TDg4LjE2Niw2OS44MzNDODQuNDk5LDY2LjE2OSw3OS4zMTgsNjQuMDcsNzQuMTM0LDY0LjE0N0w3NC4xMzQsNjQuMTQ3eiIgZmlsbD0iIzk2OTY5NiIvPg0KPC9nPg0KPC9zdmc+DQo=);
    background-position: right 10px top 50%;
    background-repeat: no-repeat;

    &::-ms-expand {
      display: none;
    }
  }

  #gdprContainer {
    display: inline-flex;

    #gdprCheckbox {
      border-width: 0 !important;
      margin-bottom: auto !important;
    }

    label {
      line-height: 1.5em;
      font-size: smaller;
      word-wrap: unset;
      word-break: unset;

      &:first-child {
        font-weight: bold;
      }

      &:last-child {
        font-weight: normal;
      }
    }
  }

  .honeyPottyCheckboxMyCheckboxFace {
    opacity: 0;
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }

  .radioButtonGroupContainer,
  .checkboxGroupContainer {
    width: 100%;
  }

  @media only screen and (max-width: 800px) {
    input[type=checkbox] {
      transform: scale(1.5);
    }
  }
}