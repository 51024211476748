.frameConsentLayer {
  --layer-bg-color: rgba(0, 0, 0, .75);
  --layer-text-color: #FFF;
  --layer-border-color: #FFF;
  --button-text-color: #000;

  --layer-font-family: Verdana;

  background: var(--layer-bg-color);
  color: var(--layer-text-color);
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.875rem;
  display: flex;
  flex-direction: column;
  border: .1rem solid var(--layer-border-color);
  font-size: .917rem;
  line-height: 16pt;
  font-family: var(--layer-font-family), Arial, sans-serif;
  position: absolute;
  z-index: auto;
  width: 100%;

  &.fullWidth {
    position: static;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  a {
    color: var(--layer-text-color);
    text-decoration: none;
  }

  button {
    margin: 1.875rem auto;
    padding: .75rem;
    background: white;
    color: var(--button-text-color);
    border: var(--layer-border-color);

    &:hover {
      background: transparent;
      color: var(--layer-text-color);
      display: inline-block;
      box-shadow: 0 0 0 .05rem #FFF, inset 0 0 0 .05rem #FFF;
    }
  }
}

.consentButton {
  background: rgba(0, 0, 0, .5);
  color: #fff;
  border: .0625rem solid #fff;
  padding: 1rem;
  margin-bottom: 13px;

  &:hover {
    background: black;
    color: #fff;
    border: .0625rem solid #fff;
    padding: 1rem;
    margin-bottom: 13px;
  }
}