#sidenav-lco {
  .LanguageChangeObject {
    width: auto !important;
    float: right;

    ul {
      display: block;
      top: 0 !important;
      height: var(--lco-height) !important;
    }

    li {
      height: var(--lco-height) !important;
      margin: 0 10px 0 0;
      padding: 0 !important;
      display: block;
      background-color: rgba(0, 0, 0, 0) !important;
      background-size: cover !important;

      a {
        background-color: rgba(0, 0, 0, 0) !important;
        height: var(--lco-height) !important;
      }
    }
  }
}

#sidenav.openSideNavigation {
  border-right: 1px solid black;
}

@media only screen and (max-width: 375px){
  #sidenav.openSideNavigation {
    width: 75%;
  }
}