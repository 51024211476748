@media screen and (max-width: 1200px) {
  html {
    --iphone-se-width: 375px;
    --iphone-12-pro-width: 390px;
    --pixel-7-width: 412px;
    --iphone-xr-width: 414px;
    --iphone-14-pro-max: 430px;

    body {
      width: auto !important;
      max-width: 100vw !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      height: unset !important;
      overflow-x: hidden !important;

      display: flex !important;
      flex-direction: column !important;
      position: relative !important;

      .positioned {
        position: relative !important;

        &.left-fixed {
          position: fixed !important;
          left: 1.25rem !important;
        }

        &.right-fixed {
          position: fixed !important;
          right: .625rem !important;
          left: initial !important;
        }
      }

      &.mobile {
        max-width: 100vw !important;
        overflow-x: hidden !important;
      }

      .childWrapper {
        max-width: 100vw !important;
      }

      @import "header";

      #fix {
        width: 0 !important;

        &:empty {
          display: none;
        }
      }

      .fullscreen-bg, #fullscreen-area {
        z-index: -1 !important;

        @media screen and (max-width: 500px) {
          background-position: 25%;
        }
        @media screen and (min-width: 501px) {
          background-position: 50%;
        }
      }

      #fullscreen-area:empty {
        //display: none;
      }

      #fix-area {
        top: 5rem !important; //to prevent from objects blocking burger menu button

        &:empty {
          display: none;
        }
      }

      .mobile-full-width {
        width: 100%;

        .childWrapper {
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      //is applied to #content and also #footer
      @import "PageObjects/TextObject";
      @import "PageObjects/LanguageChangeObject";
      @import "PageObjects/SocialMediaObject";
      @import "PageObjects/HeaderLogoObject";
      @import "PageObjects/Circle";
      @import "PageObjects/GalleryObject";
      @import "PageObjects/SliderObject";
      @import "PageObjects/imageObject";
      @import "PageObjects/ContentModule";
      @import "PageObjects/FormObject";
      @import "PageObjects/PDFViewerObject";
      @import "PageObjects/GoogleMap";
      @import "PageObjects/HtmlScriptObject";
      @import "PageObjects/IFrameObject";
      @import "PageObjects/VideoObject";

      #content {
        height: unset !important;
        width: auto !important;
        display: flex !important;
        flex-direction: column !important;

        div.mobile-center {
          order: var(--pos-nr);
        }

        #content-area {
          margin: 0;
          min-height: 100% !important;
          display: flex !important;
          flex-direction: column !important;
          width: auto !important;
          max-width: 100vw !important;
          overflow-x: hidden;
          align-items: center !important;

          & > .Box {
            width: 100vw !Important;
          }

          //set all children of a Box > Box encapsulation to not needing extra spaces
          .Box .Box .Box > .childWrapper > * {
            padding: 0 !important;
            margin: 0 !important;
          }
        }
      }
    }

    .mobile-center {
      position: relative !important;
    }

    .TextObject {
      height: unset !important;
      width: unset !important;
      left: unset !important;
      top: unset !important;
    }

    .Box {
      margin: .625rem 1.40625rem;
      top: unset !important;
      left: unset !important;
      height: unset !important;
      width: auto !important;
      max-width: 100vw !important;

      .childWrapper {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: .625rem;
        width: 100% !important;
        max-width: 100% !important;
        overflow-x: hidden !important;

        .TextObject {
          margin: 1rem;
        }

        .ImageObject {
          top: unset !important;
          left: unset !important;
        }

        div:not(.HtmlScriptObject *):not(.Circle *) {
          &:first-of-type {
            margin-top: 1.25rem;
          }

          &:last-of-type {
            margin-bottom: 1.25rem;
          }
        }
      }

      .Box {
        --box-padding: .5rem;

        width: auto !important;

        max-width: 100% !important;

        .childWrapper {
          padding: .625rem;
          width: 100%;
          max-width: 100%;

          div:first-of-type {
            margin-top: unset;
          }

          div:last-of-type {
            margin-bottom: unset;
          }
        }
      }

      .TextObject {
        --box-padding: .5rem;
        padding: 0.625rem !important;
        width: auto !important;
        display: flex;
        flex-direction: column;

        .childWrapper {
          width: 100%;
          max-width: 100%;
        }

        .childWrapper {
          .Box {
            p {
              width: 95vw !important;
            }
          }
        }
      }

      .SliderObject {
        height: unset !important;

        .fraction-slider {
          height: 100% !important;
        }
      }

      &.dcmp-full-width {
        margin-left: 0;
        margin-right: 0;
        max-width: 100vw !important;
        width: 100% !important;
      }

      & > .childWrapper > .Box {
        width: 100% !important;
      }
    }

    .TextObject {
      margin: .625rem 1.40625rem !important;
      padding: 0.625rem;
      top: unset !important;
      left: unset !important;
      height: unset !important;
      width: 100% !important;
      max-width: 100vw !important;

      text-align: center !important;
      align-self: center !important;

      ul {
        margin-left: 1rem;
        margin-top: 1rem;
        text-align: start !important;
      }
    }

    .LogoTextObject {
      left: 0 !important;
      top: 0 !important;

      .dexterlogo {
        text-wrap: nowrap;
      }
    }


    //special for individual placed text objects in boxes
    & > .Box > .TextObject {
      width: 95vw !important;
    }
  }

  .ImageObject {
    .show-fancybox.clickable {
      width: 100%;
      height: 100%;

      div {
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    img {
      max-width: 100vw !important;
    }
  }

  @import "footer";

  #xckPopup {
    max-width: 100vw !important;
    left: unset !important;
    transform: unset !important;
  }

  #xckIcon {
    right: unset !important;
  }

  //handle elements that should not be displayed mobile
  .dcmp-mobile-invisible,
  .invisible {
    display: none !important;
  }

  .TextObject:empty {
    display: none;
  }
}