@charset "UTF-8";

////
/// Custom animations to extend animation.css
/// @group animations
////

.dcmp-menu-helper-guide-animation-popup {
    animation: popupBounceAnimation cubic-bezier(.55, .06, .68, .19) 4s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards; /*when the spec is finished*/
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }

    to {
        opacity: 1;
    }
}

/// Custom zoom in animation
.zoomIn {
    animation-name: zoomIn;
}

@keyframes flip {
    from {
        transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        animation-timing-function: ease-out;
    }

    40% {
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        animation-timing-function: ease-out;
    }

    50% {
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        animation-timing-function: ease-in;
    }

    80% {
        transform: perspective(400px) scale3d(.95, .95, .95);
        animation-timing-function: ease-in;
    }

    to {
        transform: perspective(400px);
        animation-timing-function: ease-in;
        opacity: 1
    }
}

/// Custom flip animation
.animated.flip {
    backface-visibility: visible;
    animation-name: flip;
}

@keyframes flipInX {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px);
        opacity: 1;
    }
}

@keyframes flipInXDown {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, -70deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, 0deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 0deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, 0deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px) rotate3d(1, 0, 0, 0deg);
        opacity: 1;
    }
}

@keyframes flipInXUp {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 70deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, 0deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 0deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, 0deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px) rotate3d(1, 0, 0, 0deg);
        opacity: 1;
    }
}

/// Custom flip animation on x axis
.flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX;
}

@keyframes flipInY {
    from {
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);

    }

    80% {
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px);
        opacity: 1;
    }
}

@keyframes flipInYRight {
    from {
        transform: perspective(400px) rotate3d(0, 1, 0, 70deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
        opacity: 1;
    }
}

@keyframes flipInYLeft {
    from {
        transform: perspective(400px) rotate3d(0, 1, 0, -70deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
        opacity: 1;
    }
}

/// Custom flip animation on y axis
.flipInY {
    backface-visibility: visible !important;
    animation-name: flipInY;
}

@keyframes flipOutX {
    from {
        transform: perspective(400px);
    }

    30% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 1;
    }
}

/// Custom flip out animation on x axis
.flipOutX {
    animation-name: flipOutX;
    backface-visibility: visible !important;
}

@keyframes flipOutY {
    from {
        transform: perspective(400px);
    }

    30% {
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 1;
    }
}

/// Custom flip out animation on y axis
.flipOutY {
    backface-visibility: visible !important;
    animation-name: flipOutY;
}

/// Custom rotate 360 degree animation
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/// Custom rotate 720 degree animation
@keyframes rotate720 {
    0% {
        transform: rotate(0deg);
        filter: blur(0px);
    }
    20% {
        transform: rotate(100deg);
        filter: blur(1px);
    }
    80% {
        filter: blur(1px);
    }
    100% {
        transform: rotate(720deg);
        filter: blur(0px);
    }
}

/*
 * popup
 */
@keyframes popupBounceAnimation {
    0% {
        transform: scaleX(0.00) scaleY(0.00);
    }
    8% {
        transform: scaleX(1.20) scaleY(1.20);
    }
    10% {
        transform: scaleX(0.90) scaleY(0.90);
    }
    13% {
        transform: scaleX(1.10) scaleY(1.10);
    }
    16% {
        transform: scaleX(0.95) scaleY(0.95);
    }
    18% {
        transform: scaleX(1.05) scaleY(1.05);
    }
    21% {
        transform: scaleX(0.98) scaleY(0.98);
    }
    23% {
        transform: scaleX(1.02) scaleY(1.02);
    }
    26% {
        transform: scaleX(0.99) scaleY(0.99);
    }
    29% {
        transform: scaleX(1.01) scaleY(1.01);
    }
    32% {
        transform: scaleX(1.00) scaleY(1.00);
    }
    100% {
        transform: scaleX(1.00) scaleY(1.00);
    }
}

@keyframes popdownBounceAnimation {
    0% {
        transform: scaleX(1.00) scaleY(1.00);
    }
    16% {
        transform: scaleX(1.02) scaleY(1.02);
    }
    26% {
        transform: scaleX(1.10) scaleY(1.10);
    }
    32% {
        transform: scaleX(1.20) scaleY(1.20);
    }
    100% {
        transform: scaleX(0.00) scaleY(0.00);
    }
}

/*
 * fade
 */

@keyframes fadeInLeftSmall {
    from {
        opacity: 0;
        transform: translate3d(-50px, 0, 0); // Changed to increase the movement of the fadeInSmall 
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInLeftSmall {
    animation-name: fadeInLeftSmall;
}

@keyframes fadeInRightSmall {
    from {
        opacity: 0;
        transform: translate3d(50px, 0, 0);// Changed to increase the movement of the fadeInSmall 
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInRightSmall {
    animation-name: fadeInRightSmall;
}

@keyframes fadeInUpSmall {
    from {
        opacity: 0;
        transform: translate3d(0, 20px, 0); 
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInUpSmall {
    animation-name: fadeInUpSmall;
}

@keyframes fadeInDownSmall {
    from {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInDownSmall {
    animation-name: fadeInDownSmall;
}

/// To add a Slide in fade effect
/// @author Hatef Takyar
/// @since 25.10.2018
@keyframes slide-in-blurred-left {
    0% {
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform-origin: 100% 50%;
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slide-in-blurred-right {
    0% {
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform-origin: 100% 50%;
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}

/* these KeyFrames are added to add the slide-in-blurred in the project  */
@keyframes slide-in-blurred-left {
    0% {
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform-origin: 100% 50%;
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slide-in-blurred-right {
    0% {
        transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        transform-origin: 100% 50%;
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}