/// @group header

#header-main {
  border-bottom: none;

  &.onAdding {
    border-bottom: 5px solid magenta;
    min-height: calc(var(--header-height) * 1px);
    height: calc(var(--header-height) * 1px + 5px);
  }

  #header-fullwidth {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #header-childwrapper {
    position: relative;
    top: 0;
    height: 100%;
    margin: 0 auto;
    width: var(--content-width);
  }
}

#header-btn-tooltip {
  display: none;
  z-index: 10000;
  background-color: #F1F1F1;
  border: 1px solid black;
  border-radius: 3px;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  padding: 5px 9px;
}