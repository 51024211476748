.ImageObject {
  display: flex;
  position: relative;
  text-align: center;
  flex-direction: column;

  --image-margin: 2.875rem;
  --image-offset: .625rem;

  height: auto !important;
  max-height: calc(100vw / var(--aspect-ratio));

  max-width: var(--img-width) !important;
  width: calc(var(--img-width) - (var(--image-margin) - var(--image-offset)) * 2) !important;

  margin: var(--image-offset) auto !important;

  left: 0 !important;
  top: 0 !important;

  &.right-fixed, &.left-fixed {
    width: inherit !important;
    top: inherit !important;
  }

  picture {
    display: flex;
    flex-direction: column;
  }

  img {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: var(--img-width);
  }

  .layer1, .layer2, .layer3 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .layer1 {
    img {
      top: unset !important;
      left: unset !important;
      position: relative !important;
      height: auto !important;
      width: 100% !important;
    }
  }

  &.dcmp-full-width,
  &.mobile-full-width img {
    width: 100vw !important;
    margin: 0 !important;
  }

  .clickable {
    display: flex;
    justify-content: center;

    .layer1,
    .layer2,
    .layer3 {
      margin: 0;
    }
  }
}

.Box .ImageObject {
  width: 100% !important;
  max-width: var(--img-width);
}