.HtmlScriptObject {
  margin-top: .625rem;
  width: 100% !important;
  aspect-ratio: 16 / 9;
  height: max-content !important;
  top: unset !important;
  left: unset !important;

  &.right-fixed, &.left-fixed {
    width: auto !important;
  }
}