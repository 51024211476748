.dextermedia_logo {
  font-family: "Times New Roman", serif;
  font-size: 0.875rem;
  text-align: right;

  a {
    span {
      &.dexterlogo {
        text-transform: uppercase;
        letter-spacing: 0.3em;

        &::before {
          font-family: var(--font-family), serif;
          color: var(--font-color);
          font-size: var(--font-size);
          font-weight: var(--font-weight);
          font-style: var(--font-style);
          text-decoration: var(--text-decoration);

          content: attr(data-before);
        }
      }
    }

    &:visited {
      color: black;
    }
  }
}