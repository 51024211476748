.SliderObject {
  --slider-object-margin: 1.40625rem;

  display: flex;
  justify-content: center;
  left: unset !important;
  top: unset !important;
  right: unset !important;
  width: 100vw;
  aspect-ratio: 16 / 9;
  max-width: 100vw;
  max-height: calc(100vw / var(--aspect-ratio));
  align-self: center;

  &.mobile-full-width {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }

  .slider {
    margin-top: unset !important;
    margin-bottom: unset !important;
    max-width: 100vw;
  }

  .fraction-slider {
    height: 100% !important;
    margin-top: unset !important;
    margin-bottom: unset !important;
    max-width: 100vw;

    .slide {
      margin-top: unset !important;
      margin-bottom: unset !important;

      .fs_obj {
        //width: 100vw !important;
        width: unset;
        max-width: 100vw;
        height: unset;
        margin-top: unset !important;
        margin-bottom: unset !important;
        background-size: cover !important;
        object-fit: cover !important;
        background-repeat: no-repeat;

        .fs_obj {
          display: none;
        }
      }

      picture {
        object-fit: cover;
        object-position: center center;
        height: 100%;
        width: 100%;
        display: block;

        source {
          object-fit: cover;
          object-position: center center;
        }

        img {
          object-fit: cover;
          object-position: center center;
          height: 100%;
          width: 100%;
        }
      }
    }

    .next, .prev, .fs-pager-wrapper {
      display: none !important;
    }
  }

  &.dcmp-full-width {
    margin: 0 !important;
    height: 25vh !important;

    .fs_obj {
      background-size: cover !important;
      object-fit: cover !important;
    }
  }

  //extra rules for IHLE
  .slider[data-uuid$="slider"] {
    max-height: unset !important;

    @media(max-width: 500px) {
      aspect-ratio: 1;
    }

    @media(max-width: 1024px) {
      aspect-ratio: 4 / 2.917;
    }

  }

  &[data-uuid]:not([data-uuid=""]) {
    max-height: unset !important;
    width: 100% !important;
    height: 100% !important;

    @media(max-width: 767px) {
      aspect-ratio: 1 / 1 !important;

      img {
        object-fit: cover !important;
      }
    }

    @media(min-width:768px) and (max-width: 1199px) {
      aspect-ratio: 1536 / 1120 !important;

      img {
        object-fit: contain;
      }
    }

    @media(min-width: 1200px) {
      aspect-ratio: 3840 / 1320 !important;
    }
  }
}