.GalleryObject {
  overflow: hidden;

  .gallery {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: var(--image-gap);

    a {
      display: flex !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center center !important;

      width: var(--real-image-width) !important;
      height: var(--image-height) !important;
      margin-right: unset !important;
      margin-bottom: unset !important;
    }
  }
}