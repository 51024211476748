.GoogleMap {
  margin-top: .625rem;
  width: 100vw !important;
  height: 50vh !important;
  top: unset !important;
  left: unset !important;

  .frameConsentLayer {
    //noinspection CssInvalidPropertyValue
    height: -webkit-fill-available;
  }

}