.HtmlScriptObject {
  /// Code of widget object class
  .widget-title {
    display: none;
    background: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .widget-codes {
    width: 100%;
    height: 100%;
    border: transparent;
    color: var(--widget-code-bg-color) !important;
    background-color: #999 !important;
    position: absolute;
  }
}

#content {
  .HtmlScriptObject {
    text-align: center;
  }
}