////
/// Colors
/// @group fonts
////

/// @type Font
$text-font: Raleway;

/// @type Font
$icon-font: cmsbuttons;

/// @type Font
$fantasy: fantasy;

/// @type Font
$verdana: 'Verdana';

/// @type Font
$sansSerif: sans-serif;

/// @type Font
$oxygenMono: 'Oxygen Mono';

/// @type Font
$tahoma: Tahoma;

/// @type Font
$arial: Arial;