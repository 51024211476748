
////
/// Wir haben mal versucht die Ebenen der Elemente klar zu definieren.
/// Anhand der Ebenen haben wir unterschiedliche z-index Bereiche definiert.
///
/// Eine Grundlegende Aufteilung gibt es zwischen
///  Content, sowie editor, Pageobjects etc.
///    0 - 9999
///  Overlays, Menüs, Dialoge
///    10000 - 19999
///  Over everthing
///    > 100000
///
/// @group Z-Index
////

/************** Others *************************************************************/

/// Ein Super-Overlay wo keiner weiß wofür es ist
///@type Number
$super-overlay: 105000;

/* Unsere Farbauswahlen */

///@type Number
$spectrum-container: 102000;

///@type Number
$progressbar: 101000;



/************** 10000 - 19999 ******************************************************/

///Das Overlay auf dem der Handler für Drag'n'Drop definiert ist
///@type Number
$cropping-overlay: 17020;

///@type Number
$z-buttons: 14000;

$dot-helper: 160000;

/* Das sind unser Helper Button und der Sprachbutton
   Im ts Code gibt es noch inline Styles */

///@type Number
$circle-helper: 150024;

///Overlay vom helper button: 15001 (ts/helperguide/HelperGuide.class.ts)
///@type Number
$helper-textbox: 15022;

///@type Number
$circle-language: 15010;

///@type Number
$circle-buttons: 15002;


/* Unsere Editor-Menüs und der Notification Bereich für unsere "Auto-Speicherung-Nachricht" */

///@type Number
$notification-area: 11010;

///@type Number
$menu-bar: 11000;

/************** 0 - 9999 ***********************************************************/

/// Value for the rotating loader image
/// @type Number
$btn-overlay: 9999;

///@type Number
$resize-overlay-base: 9950;

/* Für unsere Magnetic Guides */

///@type Number
$editor-canvas: 8000;

///@type Number
$fix: 4000;

/// Z-Index for the footer area
/// @type Number
/// @see {CSS} .footer
$footer: 3000;

///@type Number
$verticalMenuUnsortedList: 598;