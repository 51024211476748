.SocialMediaObject {
  width: 100vw !important;
  height: unset !important;
  top: unset !important;
  left: unset !important;
  max-width: 100vw !important;

  .social-media-icons {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: calc(calc(var(--icon-width) * 1px) / 4) !important;

    &.horizontal {
      flex-direction: row;
    }

    &.vertical {
      flex-direction: column;
      align-items: center;
    }

    a {
      margin: 0 !important;
    }
  }
}