.ContentModule {
  width: 100vw !important;
  top: unset !important;
  left: unset !important;
  height: unset !important;

  .PlaceObject {
    height: unset !important;
    top: unset !important;
    left: unset !important;
  }

  .childWrapper {
    height: auto !important;
    width: 100vw !important;
    max-width: 100% !important;
    margin: 0;

    display: flex;
    flex-direction: column;

    .ImageObject {
      height: auto !important;
      max-height: 100% !important;
      margin-left: unset !important;
      margin-right: unset !important;
      width: 100% !important;

      img {
        max-width: 100% !important;
        height: auto !important;
      }

      a {
        display: block;
        width: 100%;

        div {
          width: 100% !important;
          height: 100% !important;
          background-position: center !important;
        }
      }
    }
  }
}