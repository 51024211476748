xbee-cookie {
    display:none;
}

div#xckIcon {
    font-family: Verdana,Geneva,Tahoma,sans-serif;
    font-size: 8pt;
    line-height: 16pt;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;

    position: fixed;
    background-color: rgba(0,0,0,.75);
    border: 0.1rem solid #fff;
    bottom: 0;
    right: 0;
    z-index: 9999;
    cursor: pointer;
    > span {
        color: white;
    }
}

div#xckPopup {
    font-family: Verdana,Geneva,Tahoma,sans-serif;
    font-size: 11pt;
    line-height: 16pt;

    background-color: rgba(0,0,0,.75);
    width: 46rem;
    min-width: 320px !important;
    height: auto;
    padding: 2.8125rem;
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    border: 0.1rem solid #fff;
    border-bottom: unset;

    > p#xckText {
        margin-bottom: 2.8215rem;

        > a:link {
            font-weight: bold;
            text-decoration: none;
            color: inherit;
        }
        > a:visited {
            font-weight: bold;
            text-decoration: none;
            color: inherit;
        }
    }

    > div#xckInteraction {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div#xckCheckboxDiv {
            > input[type="checkbox"]:checked, input[type="checkbox"]:not(:checked) {
                display: none;
            }

            > input[type="checkbox"]:checked, input[type="checkbox"]:not(:checked) {
            & + label {
                    position: relative;
                    padding-left: 1.75rem;
                    cursor: pointer;
                    line-height: 1.25rem;

                    /** outside */
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 1.125rem;
                        height: 1.125rem;
                        border: 0.1rem solid #ddd;
                        background: #fff;
                    }

                    /** inside */
                    &:after {
                        content: '';
                        width: .75rem;
                        height: .75rem;
                        transform: scaleX(-1) !important;
                        background-size: contain;
                        background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" preserveAspectRatio="none"><path fill="black" transform="rotate(180,525,450)" d="M797.94 911.141c51.492-25.916 132.388-70.168 183.906-95.768-229.18-225.232-447.902-537.112-522.874-850.724-105.546 113.518-279.356 235.204-418.742 314.198 18.578 57.904 54.248 109.104 90.502 157.574 79.848-50.59 223.5-168.984 258.34-217.722 62.294 171.008 230.888 511.414 408.868 692.444z"> </path> </svg>');
                        position: absolute;
                        top: .2rem;
                        left: .2rem;
                        transition: all 0.2s ease;
                    }
                }
            }

            input[type="checkbox"]:not(:checked) {
                & + label:after {
                  opacity: 0;
                  transform: scale(0);
                }
              }
              
            input[type="checkbox"]:checked {
            & + label:after {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        > div#xckButtonDiv {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 28em;

            > button {
                color: white;
                background-color: rgba(0, 0, 0, 0);
                border: 1px solid white;
                font-size: 1em;
                width: 10.625rem;
                height: 2.125rem;
                cursor: pointer;
        
                &:hover {
                    color: black;
                    background-color: white;
                }
    
                &:first-child {
                    color: black;
                    background-color: white;
                    margin-right: 2rem;
    
                    &:hover {
                        color: white;
                        background-color: rgba(0, 0, 0, 0);
                    }
                }
            }
        }
        
    }
}

@media screen and (max-width: 597px) {
    #xckInteraction {
        flex-wrap: wrap;

        #xckCheckboxDiv {
            align-self: self-start;
            margin-bottom: .8em;
        }

        #xckButtonDiv {
            width: 100% !important;
            button {
                width: 100% !important;
        
                &:first-child {
                    margin: 0 0 0.8em 0 !important;
                }
            }
        }
    }
}

div.xb-cookie-kit {
    // Header entfernen
    > div.xb-cookie-popup__header {
        display: none;
    }

    // Sprachauswahl entfernen
    > div.xb-cookie-popup__locale-container {
        display: none;
    }


    /// Das Icon, das erscheint, wenn der Hinweis weggeklickt ist
    > button.xb-cookie-kit__cookie-button > div.xb-cookie-kit__cookie-icon {
        /// SVG 
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 400 400' enable-background='new 0 0 400 400' xml:space='preserve'%3E%3Ccircle cx='197.192' cy='202.635' r='137.167'/%3E%3Cg%3E%3Cg%3E%3Cpath d='M254.351,204.859c-5.147-0.824-9.67-3.171-13.188-6.504c-6.248,2.318-13.159,3.115-20.211,1.986 c-19.185-3.069-32.984-19.298-33.673-38.004c-9.836-4.585-15.868-15.271-14.067-26.522c0.785-4.903,2.955-9.237,6.04-12.679 c-38.805,7.624-68.083,41.813-68.083,82.853c0,46.642,37.811,84.452,84.452,84.452s84.452-37.811,84.452-84.452 c0-4.131-0.311-8.189-0.884-12.162C273.98,201.833,264.391,206.465,254.351,204.859z'/%3E%3C/g%3E%3Cg%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-width='9' d='M254.351,204.859c-5.147-0.824-9.67-3.171-13.188-6.504 c-6.248,2.318-13.159,3.115-20.211,1.986c-19.185-3.069-32.984-19.298-33.673-38.004c-9.836-4.585-15.868-15.271-14.067-26.522 c0.785-4.903,2.955-9.237,6.04-12.679c-38.805,7.624-68.083,41.813-68.083,82.853c0,46.642,37.811,84.452,84.452,84.452 s84.452-37.811,84.452-84.452c0-4.131-0.311-8.189-0.884-12.162C273.98,201.833,264.391,206.465,254.351,204.859z'/%3E%3C/g%3E%3C/g%3E%3Ccircle fill='%23FFFFFF' cx='149.25' cy='189.75' r='12.75'/%3E%3Ccircle fill='%23FFFFFF' cx='156.633' cy='236.133' r='10.633'/%3E%3Ccircle fill='%23FFFFFF' cx='184.25' cy='212.75' r='6.75'/%3E%3Ccircle fill='%23FFFFFF' cx='213.25' cy='256.75' r='12.75'/%3E%3Ccircle fill='%23FFFFFF' cx='234.141' cy='119.683' r='10.139'/%3E%3Ccircle fill='%23FFFFFF' cx='207.5' cy='139.5' r='7.5'/%3E%3Ccircle fill='%23FFFFFF' cx='250.348' cy='165.684' r='6.875'/%3E%3C/svg%3E%0A");
        /// TODO: i'm sorry for these static values
        height: 150px;
        width: 150px;
        right: 75px;
        bottom: -20px;

        &--green {
            opacity: 0.5;
        }

        &--blue {
            opacity: 0.5;
        }
    }

    /// Checkboxen, wenn sie gecheckt sind
    > label.xb-cookie-kit-popup__checkbox.checked, 
    input[type=checkbox]:checked+label.xb-cookie-kit-popup__checkbox {
        border-color: white !important;
    }

    /// gesamte Popup Objekt
    div.xb-cookie-kit-popup {
        --border-props: 1px solid var(--nearly-white);

        font-family: "Helvetica", sans-serif;
        background: black;
        opacity: 0.85;
        border: 0;
        box-shadow: 10px 10px 15px black;

        label.xb-cookie-kit-popup__checkbox {
            &::before, 
            &::after {
                background: white;
            }
        }

        /// Links
        a.xb-cookie-kit-popup__link,
        div.xb-cookie-kit-popup__consent-label > a.xb-cookie-kit-popup__consent-label-link,
        button.xb-cookie-kit-popup__check-all {
            color: white;
            text-decoration: none;
    
            &:hover {
                color: var(--nearly-white);
                text-decoration: underline;
            }
        }

        /// OK Button
        button.xb-cookie-kit-popup__button {
            color: white;
            background: black;

            &:hover {
                color: black;
                background: white;
            }
        }

        /// Sprachauswahl
        div.xb-cookie-kit-popup__locale-container > div.xb-cookie-kit-popup__custom-select > button.xb-cookie-kit-popup__language-picker-button {

            color: white;
            background: black;
            border-top: var(--border-props);
            border-bottom: var(--border-props);

            &:not(:last-child) {
                border-right: var(--border-props);
            }

            &:first-child {
                border-left: var(--border-props);
            }

            &:hover {
                color: grey;
                background: var(--nearly-white);
            }
        }

        /// Länderauswahl
        div.xb-cookie-kit-popup__locale-container > div.xb-cookie-kit-popup__country-picker-select { 
            border: var(--border-props);
            /// Länderauswahl entfernen
            display: none;

            > button.xb-cookie-kit-popup__country-picker-button {
                color: white;
                background: black;
                border-bottom: var(--border-props);
    
                &:hover {
                    color: black;
                    background: white;
                }
            }
        }
    
        div.xb-cookie-kit-popup__block,
        button.xb-cookie-kit-popup__button,
        div.xb-cookie-kit-popup__country-picker,
        div.xb-cookie-kit-popup__locale-container > div.xb-cookie-kit-popup__locale > button.xb-cookie-kit-popup__language-picker,
        label.xb-cookie-kit-popup__checkbox {
            color: white;
            background: black;
            border: var(--border-props);
        }

        div.xb-cookie-kit-popup__locale-container > div.xb-cookie-kit-popup__locale > button.xb-cookie-kit-popup__language-picker,
        div.xb-cookie-kit-popup__country-picker {
            &:hover {
                color: black;
                background: white;
            }
        }

        div.xb-cookie-kit-popup__header,
        div.xb-cookie-kit-popup__locale-container > div.xb-cookie-kit-popup__locale {
            background: black;

            div.xb-cookie-kit-popup__title {
                color: white;
            }
        }

        /// Cookiehinweis Text
        div.xb-cookie-kit-popup__text {
            color: white;
            background: black;
        }

        /// Schließenbutton oben rechts
        div.xb-cookie-kit-popup__header > button.xb-cookie-kit-popup__close-button {
            background-color: var(--nearly-white);

            &:hover {
                background-color: white;
            }
        }
    }
}