@import "utilities/mixins/aosMixins";

////
/// AOS
/// @group aos
////

/// Media query for screen display type at a minimum width of 500px
@media screen and (min-width: 500px) {
  /// Start value
  $input: 100;

  /// End value
  $end: 5000;

  /// Step value for incremation
  $step: 50;
  @while $input <= $end {
    @include duration($input);
    @include delay($input);
    @include delay-animate($input);
    $input: $input + $step;
  }

  @include transition-timing-function(linear, cubic-bezier(.25, .25, .75, .75));

  @include transition-timing-function(ease, ease);
  @include transition-timing-function(ease-in, ease-in);
  @include transition-timing-function(ease-out, ease-out);
  @include transition-timing-function(ease-in-out, ease-in-out);

  @include transition-timing-function(ease-in-back, cubic-bezier(.6, -.28, .735, .045));
  @include transition-timing-function(ease-out-back, cubic-bezier(.175, .885, .32, 1.275));
  @include transition-timing-function(ease-in-out-back, cubic-bezier(.68, -.55, .265, 1.55));

  @include transition-timing-function(ease-in-sine, cubic-bezier(.47, 0, .745, .715));
  @include transition-timing-function(ease-out-sine, cubic-bezier(.39, .575, .565, 1));
  @include transition-timing-function(ease-in-out-sine, cubic-bezier(.445, .05, .55, .95));

  @include transition-timing-function(ease-in-quad, cubic-bezier(.55, .085, .68, .53));
  @include transition-timing-function(ease-out-quad, cubic-bezier(.25, .46, .45, .94));
  @include transition-timing-function(ease-in-out-quad, cubic-bezier(.455, .03, .515, .955));

  @include transition-timing-function(ease-in-cubic, cubic-bezier(.55, .085, .68, .53));
  @include transition-timing-function(ease-out-cubic, cubic-bezier(.25, .46, .45, .94));
  @include transition-timing-function(ease-in-out-cubic, cubic-bezier(.455, .03, .515, .955));

  @include transition-timing-function(ease-in-quart, cubic-bezier(.55, .085, .68, .53));
  @include transition-timing-function(ease-out-quart, cubic-bezier(.25, .46, .45, .94));
  @include transition-timing-function(ease-in-out-quart, cubic-bezier(.455, .03, .515, .955));

  [data-aos^=fade][data-aos^=fade],
  [data-aos^=fade-in][data-aos^=fade-in] {
    opacity: 0;
    transition-property: opacity, transform;
  }

  [data-aos^=fade][data-aos^=fade].aos-animate,
  [data-aos^=fade-in][data-aos^=fade-in].aos-animate {
    opacity: 1;
    transform: translate(0);
  }

  @include transform(fade-up, translateY(100px));
  @include transform(fade-down, translateY(-100px));
  @include transform(fade-right, translate(-100px));
  @include transform(fade-left, translate(100px));
  @include transform(fade-up-right, translate(-100px, 100px));
  @include transform(fade-up-left, translate(100px, 100px));
  @include transform(fade-down-right, translate(-100px, -100px));
  @include transform(fade-down-left, translate(100px, -100px));

  ///The opacity is added to make fade in active in zoom-in too - Hatef Takyar - 12.10.2018 Zoom-in problem
  [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, transform;
  }

  [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translate(0) scale(1);
  }

  @include transform(zoom-in, scale(.6));
  @include transform(zoom-in-up, translateY(100px) scale(.6));
  @include transform(zoom-in-down, translateY(-100px) scale(.6));
  @include transform(zoom-in-right, translate(-100px) scale(.6));
  @include transform(zoom-in-left, translate(100px) scale(.6));

  @include transform(zoom-out, scale(1.2));
  @include transform(zoom-out-up, translateY(100px) scale(1.2));
  @include transform(zoom-out-down, translateY(-100px) scale(1.2));
  @include transform(zoom-out-right, translate(-100px) scale(1.2));
  @include transform(zoom-out-left, translate(100px) scale(1.2));

  [data-aos^=slide][data-aos^=slide] {
    transition-property: transform;
  }

  [data-aos^=slide][data-aos^=slide].aos-animate {
    transform: translate(0);
  }

  @include transform(slide-up, translateY(100%));
  @include transform(slide-down, translateY(-100%));
  @include transform(slide-right, translateX(-100%));
  @include transform(slide-left, translateX(100%));

  [data-aos^=flip][data-aos^=flip] {
    backface-visibility: hidden;
    opacity: 0;
    transition-property: transform, opacity;
  }

  [data-aos^=flip][data-aos^=flip].aos-animate { /* The animat script is added to make the opacity function feature available in animation */
    opacity: 1;
    transform: translate(0) scale(1);
    transition-property: transform, opacity, trasform;
  }

  [data-aos=flip-left] {
    transform: perspective(400px) rotate3d(0, 1, 0, -70deg);
    animation-timing-function: ease-in;
    opacity: 0;

  }

  [data-aos=flip-left].aos-animate {
    transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
    animation-timing-function: ease-in;

    opacity: 1;

  }

  [data-aos=flip-right] {
    transform: perspective(400px) rotate3d(0, 1, 0, 70deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  [data-aos=flip-right].aos-animate {
    transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
    animation-timing-function: ease-in;
    opacity: 1;
  }

  [data-aos=flip-up] {
    transform: perspective(400px) rotate3d(1, 0, 0, -70deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  [data-aos=flip-up].aos-animate {
    transform: perspective(400px) rotate3d(1, 0, 0, 0deg);
    animation-timing-function: ease-in;
    opacity: 1;
  }

  [data-aos=flip-down] {
    transform: perspective(400px) rotate3d(1, 0, 0, 70deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  [data-aos=flip-down].aos-animate {
    transform: perspective(400px) rotate3d(1, 0, 0, 0deg);
    animation-timing-function: ease-in;
    opacity: 1;
  }
}