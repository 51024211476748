.BackgroundArea,
.BackgroundAreas {
  min-width: 100%;
  height: var(--position-height) !important;
  top: var(--position-y) !important;
  z-index: var(--position-z) !important;
  background-color: var(--background-color);
  background-image: var(--background-image-url);
  background-size: cover;

  position: absolute !important;

  &.fixed {
    position: absolute;
  }

  &.background-image-type {
    position: absolute !important;
    background-image: var(--background-image-url) !important;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &.background-repeat {
    background-repeat: repeat;
    background-size: unset;
    background-position: top left;
  }

  &.background-no-repeat {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}