button.burger {
  --burger-bg-color: #FFF; //white
  --burger-bg-open-color: #FFF; //white
  --burger-span-height: .08rem; //5px
  --burger-span-width: 1.6rem; //30px
  --burger-span-gap: -.65rem; //-13px
  --burger-span-bg-color: #000; //black
  --burger-border-color: #000; //black

  --burger-size: 2.4rem;

  left: unset !important;
  align-self: flex-end;
  box-sizing: border-box;
  display: flex;
  position: absolute !important;
  width: var(--burger-size);
  height: var(--burger-size);
  align-items: center;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 0;
  box-shadow: none;
  cursor: pointer;
  transition: background 0.2s;
  z-index: 9999999;
  background-color: var(--burger-bg-color);
  top: 1rem;
  right: 1rem;
  border-color: var(--burger-border-color);
  border-style: solid;
  border-width: .1rem;

  @media screen and (max-width: 380px) {
    right: .5rem;
  }

  &.open {
    background-color: var(--burger-bg-open-color);
    position: fixed;

    span {
      background-color: white;

      &::before {
        top: 0;
        transform: rotate(45deg);
      }

      &::after {
        bottom: 0;
        transform: rotate(-45deg);
      }

      &::before,
      &::after {
        transition-delay: 0s, 0.2s;
      }
    }
  }

  &:focus {
    outline: none;
  }

  span {
    background-color: var(--burger-span-bg-color);
    display: block;
    position: relative;
    width: var(--burger-span-width);
    height: var(--burger-span-height);
    margin: 0 auto;
    transition: background 0s 0.2s;

    &::before,
    &::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: var(--burger-span-height);
      background-color: var(--burger-span-bg-color);
      content: "";
      transition-duration: 0.2s, 0.2s;
      transition-delay: 0.2s, 0s;
    }

    &::before {
      top: var(--burger-span-gap);
      transition-property: top, transform;
    }

    &::after {
      bottom: var(--burger-span-gap);
      transition-property: bottom, transform;
    }
  }
}

#sidenav {
  --padding-left-value: .625rem;
  --padding-left-anchor-value: .9375rem;
  --padding-top-value: .063rem;

  --anchor-height: 2rem;

  grid-area: nav;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 0;
  position: fixed;
  z-index: 999999;
  background-color: white;
  overflow-x: auto;
  transition: .5s;
  border-right: none;
  font-size: 1rem;
  height: 100%;
  left: 0;
  border-right: none;

  .sidemenu-wrapper {
    display: block;
    width: 100%;
    height: auto !important;
    min-height: 100%;

    & > li > a {
      padding-left: 15px;
    }

    & > div,
    & > div > ul,
    & > div > ul > li {
      width: 100%;
    }

    & > div ul,
    & > div li {
      border-width: 0;
    }

    & > div > ul {
      overflow: hidden;
      text-align: left !important;
    }

    & > div {
      height: auto !important;
    }

    & > div ul ul {
      top: 100%;
      left: 0;
      text-align: left !important;
      padding-right: 0 !important;
      padding-left: var(--padding-left-value) !important;
    }

    & > div ul ul ul {
      left: 0 !important;
    }

    & > div > ul {
      height: auto;
      position: relative;
      text-align: left !important;
    }

    & > div a {
      padding-left: 0;
    }

    //is applied to every list item
    li {
      padding: var(--padding-top-value) 0 var(--padding-top-value) var(--padding-left-value) !important;
    }

    ul:not(.menu) {
      padding: 0 0 0 var(--padding-left-value) !important;
    }

    a {
      padding-right: 0 !important;
      padding-left: var(--padding-left-anchor-value) !important;
      text-align: start !important;
    }

    #sidenav-language-change {
      display: flex;
      flex-wrap: nowrap;

      ul {
        height: var(--anchor-height) !important;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 5px;

        li {
          display: flex;
          flex-direction: column;
          width: unset;
          height: unset;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;

          margin: 0;
          padding: 0 !important;

          a {
            height: var(--anchor-height) !important;
            width: 1.5rem !important;
            flex: 1 !important;
            padding: 0 !important;
          }

          .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
          }
        }
      }
    }

    .menu {
      display: flex !important;
      flex-direction: column !important;
      height: auto !important;

      li {
        ul {
          display: block;
          position: relative;
        }
      }
    }
  }

  &.openSideNavigation {
    border-right: .063rem solid black;
    width: 18.75rem;

    @media screen and (min-width: 820px) {
      width: 50vw;
    }

    .menu {
      scrollbar-color: var(--top-font-color) transparent;
      scrollbar-width: auto;
    }

    a {
      word-wrap: normal !important;
      word-break: keep-all !important;
      text-wrap: balance !important;
      line-height: var(--anchor-height) !important;
    }

    li {
      margin: unset !important;
      word-break: break-all;
      hyphens: auto;

      &.current:before {
        top: var(--anchor-height) !important;
      }

      a {
        margin: unset !important;
        width: 100% !important;
        height: 100% !important;
        line-height: var(--anchor-height) !important;
      }

      ul {
        li {
          margin: unset !important;

          a {
            //noinspection CssInvalidPropertyValue
            line-height: var(--anchor-height) !important;
          }
        }
      }
    }
  }
}

#sidenav-lco {
  .LanguageChangeObject {
    width: auto !important;
    float: right;

    ul {
      display: block;
      top: 0 !important;
      height: var(--lco-height) !important;
    }

    li {
      height: var(--lco-height) !important;
      margin: 0 10px 0 0;
      padding: 0 !important;
      display: block;
      background-color: rgba(0, 0, 0, 0) !important;
      background-size: cover !important;

      a {
        background-color: rgba(0, 0, 0, 0) !important;
        height: var(--lco-height) !important;
      }
    }
  }
}

.HeaderLogoImageObject {
  left: unset !important;
  top: unset !important;
  width: unset !important;
  height: unset !important;
  display: flex;

  margin-left: 2vw;
  margin-right: 15vw;
  margin-top: 1.5vh;
  align-self: flex-start;

  a {
    width: fit-content !important;
    height: fit-content !important;
  }

  .layer1, .layer2, .layer3 {
    width: unset !important;
    height: unset !important;
    position: relative !important;
  }

  img {
    position: relative !important;
    width: unset !important;
    max-width: 100% !important;
    height: auto !important;
    max-height: 15vh !important;
  }
}

header {
  display: grid !important;
  grid-template:
                "logo burger"
                "nav nav";
  position: unset !important;

  //Default
  grid-template-columns: 5fr 1fr;

  @media (max-width: var(--iphone-14-pro-max)) {
    grid-template-columns: 2fr 1fr;
  }

  justify-items: start;
  align-items: flex-start;
  height: auto !important;
  padding: 1rem 0;
  margin: 0;

  #header-fullwidth {
    width: 100vw !important;
    height: auto !important;

    .Box {
      width: 100vw !important;
    }

    .LanguageChange, .LanguageChangeObject {
      display: none;
    }

    &:empty {
      display: none;
    }
  }

  #header-childwrapper {
    grid-area: logo;
    width: 100% !important;
    max-width: 100vw !important;
    display: flex;
    flex-direction: column;
    overflow: visible !important;
    object-fit: scale-down !important;
    align-items: flex-start !important;

    div {
      left: unset !important;
      top: unset !important;
    }

    .ImageObject {
      .layer1 {
        width: 100%;
        height: 100%;
        top: 0 !important;

        img {
          top: 0 !important;
        }
      }
    }

    .TextObject {
      display: flex;
      width: unset !important;
      height: unset !important;
      flex-direction: column !important;
      margin: .15625rem 1.40625rem !important;

      span {
        white-space: nowrap;
      }
    }

    .LanguageChangeObject {
      display: none;
      top: unset !important;
      left: unset !important;
    }

    .HeaderLogoObject {
      width: unset !important;
      height: unset !important;
      order: -1;
    }

    .HeaderLogoImageObject {
      position: relative !important;
      margin-bottom: unset !important;
      order: -1;
    }

    .TextObject, .ImageObject, .Box {
      margin-left: 2vw !important;
    }

    .Box {
      align-content: center;

      .TextObject {
        margin-left: unset !important;
      }
    }
  }

  .burger {
    grid-area: burger;
    align-self: unset;
    z-index: 9999;
  }
}

.MenuItemsObject {
  display: none;
}