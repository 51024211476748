@use "sass:math";

////
/// AOS
/// @group aos
////

/// AOS duration
/// @param {number} $value - duration value for the transition
/// @output transition-duration for aos with given time
@mixin duration($value) {
  $time: math.div($value, 1000);
  [data-aos][data-aos][data-aos-duration='#{$value}'], body[data-aos-duration='#{$value}'] [data-aos] {
    transition-duration: $time + s;
  }
}

/// AOS delay
/// @param {number} $value - time value for the delay
/// @output transition-duration for aos with given time
@mixin delay($value) {
  $time: math.div($value, 1000);
  [data-aos][data-aos][data-aos-delay='#{$value}'], body[data-aos-delay='#{$value}'] [data-aos] {
    transition-delay: $time + s;
  }
}

/// AOS delay animate
/// @param {number} $value - time value for the animation delay
/// @output transition-delay for aos with given time
@mixin delay-animate($value) {
  $time: math.div($value, 1000);
  [data-aos][data-aos][data-aos-delay='#{$value}'].aos-animate, body[data-aos-delay='#{$value}'] [data-aos].aos-animate {
    transition-delay: $time + s;
  }
}

/// AOS transition timing function
/// @param {string} $action - name for the easing action
/// @param {number} $value - value for the transition
/// @output transition-timing-function for aos with given value
@mixin transition-timing-function($action, $value) {
  [data-aos][data-aos][data-aos-easing=#{$action}], body[data-aos-easing=#{$action}] [data-aos] {
    transition-timing-function: $value;
  }
}

// =============================================================================
// AOS transform
// =============================================================================

/// AOS transform
/// @param {string} $action - name for the easing action
/// @param {method} $transform - method for transform value
/// @output transform for aos with given value
@mixin transform($action, $transform) {
  [data-aos=#{$action}] {
    transform: $transform;
  }
}