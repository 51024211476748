////
/// Akzentfarbe für die resize Overlays, die Unterstriche im StyleDialog usw.
/// @group colors
////

:root {
  --black: #000;
  --accent-color: #74B742;
  --tooltip-bg-color: #FFFFFFB2;
}

/// @access public
/// @type Color
$accentColor: #74B742;

/// @access public
/// @type Color
$accentColor2: #0384fc;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$whitesmoke: #F5F5F5;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$blue: blue;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$red: red;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$grey: grey;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$yellow: yellow;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$orange: orange;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$aqua: aqua;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$uiButtonGrey: #E6E6EF;

/// @access public
/// @type Color
$nearlyGrey: #AAA;

/// @access public
/// @type Color
$nearlyPerfectGrey: #BBB;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$grayBackgroundColor: #CCC;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$whiteBackgroundColor: #DDD;

/// @access public
/// @type Color
/// @author Marcel Dominic Bandowski
$white: #FFF;