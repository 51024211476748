.MenuItemsObject {
  width: var(--position-width) !important;
  left: var(--position-x) !important;
  top: var(--position-y) !important;
  z-index: var(--position-z) !important;

  position: absolute;
}

#mobile-menu,
.MenuItemsObject {

  ul[id^="menu-"].menu {
    width: var(--calculated-width);

    position: var(--position-fixed) !important;
    text-align: var(--text-align) !important;
    height: var(--top-line-height) !important;
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      white-space: nowrap;
    }

    li {
      line-height: 1;

      &:hover {
        background-color: var(--hover-background-color) !important;
        color: var(--hover-font-color) !important;

        & > a {
          background-color: transparent !important;
          color: var(--hover-font-color) !important;
          font-style: var(--hover-font-style) !important;
          font-weight: var(--hover-font-weight) !important;
          text-decoration: var(--hover-text-decoration) !important;
          transition: all 0.5s;
        }

        & > ul {
          display: block;
          transition: background-color 0.5s;
          animation: var(--submenu-animation) .3s ease-in;
        }
      }

      &.current {
        background-color: var(--current-other-level-background-color) !important;

        &::before {
          content: "";
          display: inline-block;
          width: 90%;
          position: absolute;
          left: 5%;
          top: var(--current-top-level-style-line-vertical-position);
          height: var(--current-top-level-style-line-height);
          background-color: var(--current-top-level-style-line-color);
        }

        ul {
          li {
            &::before {
              content: "";
              display: inline-block;
              width: 90%;
              position: absolute;
              left: 5%;
              top: var(--current-other-level-style-line-vertical-position);
              height: var(--current-other-level-style-line-height);
              background-color: var(--current-other-level-style-line-color);
            }

            &.current {
              & > a {
                color: var(--current-other-level-font-color) !important;
                font-weight: var(--current-other-level-font-weight) !important;
                font-style: var(--current-other-level-font-style) !important;
                text-decoration: var(--current-other-level-text-decoration) !important;
              }
            }

            &:hover {
              & > a {
                background-color: var(--hover-other-level-background-color) !important;
                color: var(--hover-other-level-font-color) !important;
              }
            }
          }
        }
      }
    }

    //first layer
    & > li {
      position: relative;
      margin: 0;
      font-size: var(--top-font-size) !important;
      background-color: var(--top-background-color) !important;

      &:not(:first-child) {
        border-top: var(--top-border-top) !important;
        border-left: var(--top-border-left) !important;
      }

      &.current {
        background-color: var(--current-top-level-background-color) !important;

        &::before {
          content: "";
          display: inline-block;
          width: 90%;
          position: absolute;
          left: 5%;
        }

        & > a {
          color: var(--current-top-level-font-color) !important;
          font-weight: var(--current-top-level-font-weight) !important;
          font-style: var(--current-top-level-font-style) !important;
          text-decoration: var(--current-top-level-text-decoration) !important;
        }
      }

      & > a {
        margin: 0;
        color: var(--top-font-color) !important;
        font-family: var(--top-font-family), sans-serif !important;
        font-weight: var(--top-font-weight) !important;
        font-style: var(--top-font-style) !important;
        text-decoration: var(--top-text-decoration) !important;
        display: inline-block;
        padding: 0 15px;
      }
    }

    //specific styles for vertical menus
    &.vertical {
      height: inherit;

      & > li {
        padding: var(--top-padding-vertical) var(--top-padding-horizontal);
        display: block;

        &.current {
          background-color: transparent;

          & > a {
            background-color: var(--current-top-level-background-color) !important;
          }
        }
      }

      a {
        width: 100%;
      }

      & > a {
        padding: var(--top-padding-vertical) var(--top-padding-horizontal);
        display: inline-block;
        background-color: var(--top-background-color) !important;
      }

      ul {
        top: 0 !important;
        left: 100%;

        li {
          padding: 0;
          background-color: transparent;
          border-bottom: var(--border-bottom) !important;
          border-left: var(--border-left) !important;

          &:first-child {
            border-top: var(--border-top) !important;
            border-left: var(--border-left) !important;
          }

          &:hover {
            background-color: transparent;

            & > a {
              background-color: var(--hover-other-level-background-color);
            }
          }

          &.current {
            background-color: transparent;

            & > a {
              background-color: var(--current-other-level-background-color);
            }
          }
        }

        a {
          padding: var(--other-padding-vertical) var(--other-padding-horizontal);
          background-color: var(--other-background-color);
        }
      }
    }

    //specific styles for horizontal menus
    &.horizontal {
      & > li {
        padding: 0 var(--top-padding-horizontal);
        display: inline-block;

        & > a {
          line-height: var(--top-line-height) !important;
        }
      }

      ul {
        top: 100%;
      }
    }

    //second layer, submenus
    ul {
      display: none;
      position: absolute;
      padding: 0;

      li {
        position: relative;
        display: block;
        width: 100%;
        margin: 0;
        background-color: var(--other-background-color) !important;
        padding: var(--other-padding-vertical) var(--other-padding-horizontal);
        border-bottom: var(--border-bottom) !important;
        border-left: var(--border-left) !important;
        text-align: var(--other-level-text-align) !important;

        &:hover {
          background-color: var(--hover-other-level-background-color) !important;

          & > a {
            color: var(--hover-other-level-font-color) !important;
            font-style: var(--hover-other-level-font-style) !important;
          }

          & > ul {
            display: block;
            animation: var(--other-submenu-animation) .3s ease-in;
          }
        }

        &:first-child {
          border-top: var(--border-top) !important;
          border-left: var(--border-left) !important;
        }
      }

      a {
        display: block;
        padding: 0;
        line-height: 1;
        font-size: var(--other-font-size);
        color: var(--other-font-color);
        font-family: var(--other-font-family), sans-serif;
        font-weight: var(--other-font-weight);
        font-style: var(--other-font-style);
        text-decoration: var(--other-text-decoration);
      }

      ul {
        top: 0 !important;
        left: 100%;
      }
    }

    &.scroll-down-left {
      ul {
        display: block;
        opacity: 0;
        transition: opacity .25s ease .1s;

        a {
          line-height: var(--submenu-animation-line-height);
        }

        li {
          height: 0;
          overflow: hidden;
          transition: height .25s ease .1s;
          padding-top: 0;
          padding-bottom: 0;

          &:hover > ul {
            opacity: 1;

            & > li {
              height: var(--submenu-animation-line-height);
              overflow: visible;
            }
          }
        }
      }
    }
  }
}