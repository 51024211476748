@import "utilities/colors/globalColors";
@import "utilities/colors/globalShadowColors";
@import "utilities/variables/fontVariables";
@import "utilities/variables/global-z-indices";

/// @access private
/// @type Color
/// @author Marcel Dominic Bandowski
$backgroundAreaBackgroundColor: rgba(136, 137, 120, .24);

/// @access private
/// @type Color
/// @author Marcel Dominic Bandowski
$verticalMenuBackgroundColor: #333333;

/// @access private
/// @type Color
/// @author Marcel Dominic Bandowski
$verticalMenuHoverBackgroundColor: #4EB1FF;

/// @access private
/// @type Color
/// @author Marcel Dominic Bandowski
$verticalMenuActiveBackgroundColor: #1B9BFF;

/// @access private
/// @type Color
/// @author Marcel Dominic Bandowski
$verticalMenuBorderColor: #0082E7;


.circle, .Circle {
  background-color: $grey;
  border-radius: 100%;
}

.BackgroundArea {
  background-color: $backgroundAreaBackgroundColor;
  width: 100% !important;
}

.cropBox, .CropBox {
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.5);
  z-index: var(--crop-box-z-index);
}

//-----------------------------------------------------------Vertical menu CSS --------------------------------------------------

.menu-vertical,
.menu-vertical li,
.menu-vertical ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Custom CSS Styles */
.menu-vertical {
  border: 0;
  line-height: 1;
  border-bottom: 1px solid $verticalMenuBorderColor;
  background: var(--black);
  position: relative;
  z-index: 597;
  float: left;
  width: 200px;
  background: $verticalMenuBackgroundColor;
  font-family: $oxygenMono, $tahoma, $arial, $sansSerif;
  zoom: 1;
  font-size: 12px;

  a {
    display: block;
    padding: 15px 20px;
    font-weight: 700;
    font-size: 16px;
    color: var(--black);
    text-decoration: none;
    text-transform: uppercase;
  }

  ul {
    visibility: hidden;
    position: absolute;
    z-index: $verticalMenuUnsortedList;
    top: 1px;
    left: 99%;
    margin-top: 1px;
    width: 12.5rem;
    border-radius: 0 3px 3px 0;
    border: 1px solid $verticalMenuBorderColor;

    a {
      font-size: .75rem;
      color: $white;

      &:hover {
        color: $white;
      }
    }

    li {
      float: none;
      font-weight: normal;
      border-bottom: .063rem solid $verticalMenuBorderColor;
      background: var(--black);

      &:hover > a {
        background: $verticalMenuHoverBackgroundColor;
        color: $white;
      }
    }

    ul {
      top: -2px;
      right: 0;
    }
  }

  li {
    min-height: 1px;
    line-height: 1em;
    vertical-align: middle;
    float: none;
    position: relative;

    &:hover > ul {
      visibility: visible;
    }

    &.hover, &:hover {
      position: relative;
      z-index: 599;
      cursor: default;
    }

    &.has-sub > a:after {
      content: '+';
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -6px;
    }

    &.first {
      border-radius: 0 3px 0 0;
    }

    &.last {
      border-radius: 0 0 3px 0;
      border-bottom: 0;
    }

  }

  & > ul {
    width: 200px;
  }

  & > ul > li {
    & > a, & > ul > li > a:hover {
      color: var(--white);
    }

    &.active a {
      background: $verticalMenuActiveBackgroundColor;
    }

    a:hover, & > ul > li:hover a {
      background: $verticalMenuActiveBackgroundColor;
    }
  }

  &.align-right {
    float: right;

    li {
      text-align: right;
    }

    ul {
      li.has-sub > a {
        &:before {
          content: '+';
          position: absolute;
          top: 50%;
          left: 15px;
          margin-top: -6px;
        }

        &:after {
          content: none;
        }
      }

      ul {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: -100%;
        z-index: 598;
        width: 100%;
        border-radius: 3px 0 0 3px;

        li {
          &.first {
            border-radius: 3px 0 0 0;
          }

          &.last {
            border-radius: 0 0 0 3px;
          }
        }
      }
    }

    & > ul > li > a {
      border-left: .25rem solid $verticalMenuActiveBackgroundColor;
      border-right: none;
    }
  }

  &::before {
    content: '';
    display: block;
  }

  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

//------------------------------------------------------ End of Vertical menu CSS --------------------------------------------------
//------------------------------------------------------ start of background setting--------------------------------


// ---------------------------------------------------------Full Width ChildWrapper------------------------------------------
//height resize
.box, .Box {
  .childWrapper {
    height: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }
}

#footer {
  color: black !important;
}