:root {
  //colors
  --black: #000;
  --middle-red: #700;

  --widget-bg-color: #999999;

  --nearly-grey: #AAA;
  --nearly-perfect-grey: #BBB;
  --background-grey: #CCC;
  --nearly-white: #EEE;

  --white-smoke: #F5F5F5;
  --white: #FFF;

  --ffb-box-background-color: #E6E6E6;
  --widget-code-bg-color: #010A0F;
  --dialog-hover-btn-bg-color: rgb(236, 236, 236);

  --circle-button-background-color: #FAFAFA;

  --transparent: #FFFFFF00;
}