@use "sass:string";
@import "utilities/functions/functions";

/// Creates a font-face output that contain basic WOFF2 font path
/// @param {String} $name - Name of the font-family
/// @param {String} $path - Path for the chosen font file
/// @param {String} $weight [null] - Value for font-weight
/// @param {String} $style [null] - Value for font-style
@mixin font-face($name, $path, $weight: null, $style: null) {
  @font-face {
    font-family: '#{$name}';
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
    src: url("#{$path}.woff2") format("woff2");
  }
}

/// @type Path
$fontsPath: "https://cms.dextermedia.de/dcmpro/fonts";

//Bauhaus
@include font-face("Bauhaus", "#{$fontsPath}/bauhaus/bauhaus", normal, normal);
@include font-face("Bauhaus", "#{$fontsPath}/bauhaus/bauhausb", bold, normal);
@include font-face("Bauhaus", "#{$fontsPath}/bauhaus/bauhausi", normal, italic);
@include font-face("Bauhaus", "#{$fontsPath}/bauhaus/bauhausz", bold, italic);

//Baloo
@include font-face("Baloo", "#{$fontsPath}/baloo/BalooPaaji-Regular", normal, normal);

//Barlow
@include font-face("Barlow-Black", "#{$fontsPath}/barlow/Barlow-Black", bold, normal);
@include font-face("Barlow-Black", "#{$fontsPath}/barlow/Barlow-BlackItalic", bold, italic);
@include font-face("Barlow", "#{$fontsPath}/barlow/Barlow-Bold", bold, normal);
@include font-face("Barlow", "#{$fontsPath}/barlow/Barlow-BoldItalic", bold, italic);
@include font-face("Barlow-ExtraBold", "#{$fontsPath}/barlow/Barlow-ExtraBold", bold, normal);
@include font-face("Barlow-ExtraBold", "#{$fontsPath}/barlow/Barlow-ExtraBoldItalic", bold, italic);
@include font-face("Barlow-ExtraLight", "#{$fontsPath}/barlow/Barlow-ExtraLight", normal, normal);
@include font-face("Barlow-ExtraLight", "#{$fontsPath}/barlow/Barlow-ExtraLightItalic", normal, italic);
@include font-face("Barlow", "#{$fontsPath}/barlow/Barlow-Italic", normal, italic);
@include font-face("Barlow-Light", "#{$fontsPath}/barlow/Barlow-Light", normal, normal);
@include font-face("Barlow-Light", "#{$fontsPath}/barlow/Barlow-LightItalic", normal, italic);
@include font-face("Barlow-Medium", "#{$fontsPath}/barlow/Barlow-Medium", normal, normal);
@include font-face("Barlow-Medium", "#{$fontsPath}/barlow/Barlow-MediumItalic", normal, italic);
@include font-face("Barlow", "#{$fontsPath}/barlow/Barlow-Regular", normal, normal);
@include font-face("Barlow-SemiBold", "#{$fontsPath}/barlow/Barlow-SemiBold", bold, normal);
@include font-face("Barlow-SemoBold", "#{$fontsPath}/barlow/Barlow-SemiBoldItalic", bold, italic);
@include font-face("Barlow-Thin", "#{$fontsPath}/barlow/Barlow-Thin", normal, normal);
@include font-face("Barlow-Thin", "#{$fontsPath}/barlow/Barlow-ThinItalic", normal, italic);

//Calligraffiti
@include font-face("Calligraffiti", "#{$fontsPath}/calligraffiti/calligraffiti", normal, normal);

//CMS Buttons
@include font-face("cmsbuttons", "#{$fontsPath}/cmsbuttons/cmsbuttons", normal, normal);

//Coronet
@include font-face("Coronet", "#{$fontsPath}/coronet/coronet", normal, normal);
@include font-face("Coronet", "#{$fontsPath}/coronet/coronetb", bold, normal);
@include font-face("Coronet", "#{$fontsPath}/coronet/coroneti", normal, italic);
@include font-face("Coronet", "#{$fontsPath}/coronet/coronetz", bold, italic);

//DancingScript
@include font-face("DancingScript", "#{$fontsPath}/dancing_script/dancing_script", normal, normal);

//Encode Sans
@include font-face("Encode Sans", "#{$fontsPath}/encodeSans/EncodeSans-Regular", normal, normal);
@include font-face("Encode Sans", "#{$fontsPath}/encodeSans/EncodeSans-Bold", bold, normal);
@include font-face("Encode Sans", "#{$fontsPath}/encodeSans/EncodeSans-Thin", 100, normal);
@include font-face("Encode Sans", "#{$fontsPath}/encodeSans/EncodeSans-ExtraLight", 200, normal);
@include font-face("Encode Sans", "#{$fontsPath}/encodeSans/EncodeSans-Light", 300, normal);
@include font-face("Encode Sans", "#{$fontsPath}/encodeSans/EncodeSans-Regular", 400, normal);
@include font-face("Encode Sans", "#{$fontsPath}/encodeSans/EncodeSans-Medium", 500, normal);
@include font-face("Encode Sans", "#{$fontsPath}/encodeSans/EncodeSans-SemiBold", 600, normal);
@include font-face("Encode Sans", "#{$fontsPath}/encodeSans/EncodeSans-Bold", 700, normal);
@include font-face("Encode Sans", "#{$fontsPath}/encodeSans/EncodeSans-ExtraBold", 800, normal);
@include font-face("Encode Sans", "#{$fontsPath}/encodeSans/EncodeSans-Black", 900, normal);

//Encode Sans Condensed
@include font-face("Encode Sans Condensed", "#{$fontsPath}/encodeSansCondensed/EncodeSansCondensed-Regular", normal, normal);
@include font-face("Encode Sans Condensed", "#{$fontsPath}/encodeSansCondensed/EncodeSansCondensed-Bold", bold, normal);
@include font-face("Encode Sans Condensed", "#{$fontsPath}/encodeSansCondensed/EncodeSansCondensed-Thin", 100, normal);
@include font-face("Encode Sans Condensed", "#{$fontsPath}/encodeSansCondensed/EncodeSansCondensed-ExtraLight", 200, normal);
@include font-face("Encode Sans Condensed", "#{$fontsPath}/encodeSansCondensed/EncodeSansCondensed-Light", 300, normal);
@include font-face("Encode Sans Condensed", "#{$fontsPath}/encodeSansCondensed/EncodeSansCondensed-Regular", 400, normal);
@include font-face("Encode Sans Condensed", "#{$fontsPath}/encodeSansCondensed/EncodeSansCondensed-Medium", 500, normal);
@include font-face("Encode Sans Condensed", "#{$fontsPath}/encodeSansCondensed/EncodeSansCondensed-SemiBold", 600, normal);
@include font-face("Encode Sans Condensed", "#{$fontsPath}/encodeSansCondensed/EncodeSansCondensed-Bold", 700, normal);
@include font-face("Encode Sans Condensed", "#{$fontsPath}/encodeSansCondensed/EncodeSansCondensed-ExtraBold", 800, normal);
@include font-face("Encode Sans Condensed", "#{$fontsPath}/encodeSansCondensed/EncodeSansCondensed-Black", 900, normal);

//Encode Sans Expanded
@include font-face("Encode Sans Expanded", "#{$fontsPath}/encodeSansExpanded/EncodeSansExpanded-Regular", normal, normal);
@include font-face("Encode Sans Expanded", "#{$fontsPath}/encodeSansExpanded/EncodeSansExpanded-Bold", bold, normal);
@include font-face("Encode Sans Expanded", "#{$fontsPath}/encodeSansExpanded/EncodeSansExpanded-Thin", 100, normal);
@include font-face("Encode Sans Expanded", "#{$fontsPath}/encodeSansExpanded/EncodeSansExpanded-ExtraLight", 200, normal);
@include font-face("Encode Sans Expanded", "#{$fontsPath}/encodeSansExpanded/EncodeSansExpanded-Light", 300, normal);
@include font-face("Encode Sans Expanded", "#{$fontsPath}/encodeSansExpanded/EncodeSansExpanded-Regular", 400, normal);
@include font-face("Encode Sans Expanded", "#{$fontsPath}/encodeSansExpanded/EncodeSansExpanded-Medium", 500, normal);
@include font-face("Encode Sans Expanded", "#{$fontsPath}/encodeSansExpanded/EncodeSansExpanded-SemiBold", 600, normal);
@include font-face("Encode Sans Expanded", "#{$fontsPath}/encodeSansExpanded/EncodeSansExpanded-Bold", 700, normal);
@include font-face("Encode Sans Expanded", "#{$fontsPath}/encodeSansExpanded/EncodeSansExpanded-ExtraBold", 800, normal);
@include font-face("Encode Sans Expanded", "#{$fontsPath}/encodeSansExpanded/EncodeSansExpanded-Black", 900, normal);

//Encode Sans Semi Condensed
@include font-face("Encode Sans Semi Condensed", "#{$fontsPath}/encodeSansSemiCondensed/EncodeSansSemiCondensed-Regular", normal, normal);
@include font-face("Encode Sans Semi Condensed", "#{$fontsPath}/encodeSansSemiCondensed/EncodeSansSemiCondensed-Bold", bold, normal);
@include font-face("Encode Sans Semi Condensed", "#{$fontsPath}/encodeSansSemiCondensed/EncodeSansSemiCondensed-Thin", 100, normal);
@include font-face("Encode Sans Semi Condensed", "#{$fontsPath}/encodeSansSemiCondensed/EncodeSansSemiCondensed-ExtraLight", 200, normal);
@include font-face("Encode Sans Semi Condensed", "#{$fontsPath}/encodeSansSemiCondensed/EncodeSansSemiCondensed-Light", 300, normal);
@include font-face("Encode Sans Semi Condensed", "#{$fontsPath}/encodeSansSemiCondensed/EncodeSansSemiCondensed-Regular", 400, normal);
@include font-face("Encode Sans Semi Condensed", "#{$fontsPath}/encodeSansSemiCondensed/EncodeSansSemiCondensed-Medium", 500, normal);
@include font-face("Encode Sans Semi Condensed", "#{$fontsPath}/encodeSansSemiCondensed/EncodeSansSemiCondensed-SemiBold", 600, normal);
@include font-face("Encode Sans Semi Condensed", "#{$fontsPath}/encodeSansSemiCondensed/EncodeSansSemiCondensed-Bold", 700, normal);
@include font-face("Encode Sans Semi Condensed", "#{$fontsPath}/encodeSansSemiCondensed/EncodeSansSemiCondensed-ExtraBold", 800, normal);
@include font-face("Encode Sans Semi Condensed", "#{$fontsPath}/encodeSansSemiCondensed/EncodeSansSemiCondensed-Black", 900, normal);

//Encode Sans Semi Expanded
@include font-face("Encode Sans Semi Expanded", "#{$fontsPath}/encodeSansSemiExpanded/EncodeSansSemiExpanded-Regular", normal, normal);
@include font-face("Encode Sans Semi Expanded", "#{$fontsPath}/encodeSansSemiExpanded/EncodeSansSemiExpanded-Bold", bold, normal);
@include font-face("Encode Sans Semi Expanded", "#{$fontsPath}/encodeSansSemiExpanded/EncodeSansSemiExpanded-Thin", 100, normal);
@include font-face("Encode Sans Semi Expanded", "#{$fontsPath}/encodeSansSemiExpanded/EncodeSansSemiExpanded-ExtraLight", 200, normal);
@include font-face("Encode Sans Semi Expanded", "#{$fontsPath}/encodeSansSemiExpanded/EncodeSansSemiExpanded-Light", 300, normal);
@include font-face("Encode Sans Semi Expanded", "#{$fontsPath}/encodeSansSemiExpanded/EncodeSansSemiExpanded-Regular", 400, normal);
@include font-face("Encode Sans Semi Expanded", "#{$fontsPath}/encodeSansSemiExpanded/EncodeSansSemiExpanded-Medium", 500, normal);
@include font-face("Encode Sans Semi Expanded", "#{$fontsPath}/encodeSansSemiExpanded/EncodeSansSemiExpanded-SemiBold", 600, normal);
@include font-face("Encode Sans Semi Expanded", "#{$fontsPath}/encodeSansSemiExpanded/EncodeSansSemiExpanded-Bold", 700, normal);
@include font-face("Encode Sans Semi Expanded", "#{$fontsPath}/encodeSansSemiExpanded/EncodeSansSemiExpanded-ExtraBold", 800, normal);
@include font-face("Encode Sans Semi Expanded", "#{$fontsPath}/encodeSansSemiExpanded/EncodeSansSemiExpanded-Black", 900, normal);

//Existence
/** @author Yeah Noah */
@include font-face("Existence", "#{$fontsPath}/existence/existence", normal, normal);

//Exo
@include font-face("Exo", "#{$fontsPath}/exo/Exo-Regular", normal, normal);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-Bold", bold, normal);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-Italic", normal, italic);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-Thin", 100, normal);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-ThinItalic", 100, italic);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-ExtraLight", 200, normal);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-ExtraLightItalic", 200, italic);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-Light", 300, normal);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-LightItalic", 300, italic);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-Regular", 400, normal);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-Medium", 500, normal);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-MediumItalic", 500, italic);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-SemiBold", 600, normal);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-SemiBoldItalic", 600, italic);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-Bold", 700, normal);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-BoldItalic", 700, italic);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-ExtraBold", 800, normal);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-ExtraBoldItalic", 800, italic);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-Black", 900, normal);
@include font-face("Exo", "#{$fontsPath}/exo/Exo-BlackItalic", 900, italic);

//Exo 2
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-Regular", normal, normal);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-Bold", bold, normal);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-Italic", normal, italic);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-Thin", 100, normal);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-ThinItalic", 100, italic);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-ExtraLight", 200, normal);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-ExtraLightItalic", 200, italic);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-Light", 300, normal);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-LightItalic", 300, italic);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-Regular", 400, normal);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-Medium", 500, normal);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-MediumItalic", 500, italic);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-SemiBold", 600, normal);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-SemiBoldItalic", 600, italic);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-Bold", 700, normal);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-BoldItalic", 700, italic);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-ExtraBold", 800, normal);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-ExtraBoldItalic", 800, italic);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-Black", 900, normal);
@include font-face("Exo 2", "#{$fontsPath}/exo2/Exo2-BlackItalic", 900, italic);

//Exo 2 Condensed
@include font-face("Exo 2 Condensed", "#{$fontsPath}/exo2Condensed/Exo2-RegularCondensed", normal, normal);
@include font-face("Exo 2 Condensed", "#{$fontsPath}/exo2Condensed/Exo2-BoldCondensed", bold, normal);
@include font-face("Exo 2 Condensed", "#{$fontsPath}/exo2Condensed/Exo2-ThinCondensed", 100, normal);
@include font-face("Exo 2 Condensed", "#{$fontsPath}/exo2Condensed/Exo2-ExtraLightCondensed", 200, normal);
@include font-face("Exo 2 Condensed", "#{$fontsPath}/exo2Condensed/Exo2-LightCondensed", 300, normal);
@include font-face("Exo 2 Condensed", "#{$fontsPath}/exo2Condensed/Exo2-RegularCondensed", 400, normal);
@include font-face("Exo 2 Condensed", "#{$fontsPath}/exo2Condensed/Exo2-MediumCondensed", 500, normal);
@include font-face("Exo 2 Condensed", "#{$fontsPath}/exo2Condensed/Exo2-SemiBoldCondensed", 600, normal);
@include font-face("Exo 2 Condensed", "#{$fontsPath}/exo2Condensed/Exo2-BoldCondensed", 700, normal);
@include font-face("Exo 2 Condensed", "#{$fontsPath}/exo2Condensed/Exo2-ExtraBoldCondensed", 800, normal);

//Exo 2 Expanded
@include font-face("Exo 2 Expanded", "#{$fontsPath}/exo2Expanded/Exo2-RegularExpanded", normal, normal);
@include font-face("Exo 2 Expanded", "#{$fontsPath}/exo2Expanded/Exo2-BoldExpanded", bold, normal);
@include font-face("Exo 2 Expanded", "#{$fontsPath}/exo2Expanded/Exo2-ThinExpanded", 100, normal);
@include font-face("Exo 2 Expanded", "#{$fontsPath}/exo2Expanded/Exo2-ExtraLightExpanded", 200, normal);
@include font-face("Exo 2 Expanded", "#{$fontsPath}/exo2Expanded/Exo2-LightExpanded", 300, normal);
@include font-face("Exo 2 Expanded", "#{$fontsPath}/exo2Expanded/Exo2-RegularExpanded", 400, normal);
@include font-face("Exo 2 Expanded", "#{$fontsPath}/exo2Expanded/Exo2-MediumExpanded", 500, normal);
@include font-face("Exo 2 Expanded", "#{$fontsPath}/exo2Expanded/Exo2-SemiBoldExpanded", 600, normal);
@include font-face("Exo 2 Expanded", "#{$fontsPath}/exo2Expanded/Exo2-BoldExpanded", 700, normal);
@include font-face("Exo 2 Expanded", "#{$fontsPath}/exo2Expanded/Exo2-ExtraBoldExpanded", 800, normal);
@include font-face("Exo 2 Expanded", "#{$fontsPath}/exo2Expanded/Exo2-BlackExpanded", 900, normal);

//Indie Flower
@include font-face("Indie Flower", "#{$fontsPath}/indie_flower/indieflower", normal, normal);

//Kia
@include font-face("Kia", "#{$fontsPath}/kia/kiaLight", 200, normal);
@include font-face("Kia", "#{$fontsPath}/kia/kiaMedium", 500, normal);
@include font-face("Kia", "#{$fontsPath}/kia/kiaBold", 700, normal);

//League Gothic
@include font-face("League Gothic", "#{$fontsPath}/leagueGothic/leagueGothicRegular", normal, normal);
@include font-face("League Gothic", "#{$fontsPath}/leagueGothic/leagueGothicItalic", normal, italic);

//League Gothic Condensed
@include font-face("League Gothic Condensed", "#{$fontsPath}/leagueGothic/leagueGothicCondensedRegular", normal, normal);
@include font-face("League Gothic Condensed", "#{$fontsPath}/leagueGothic/leagueGothicCondensedItalic", normal, italic);

//Legault
@include font-face("Legault", "#{$fontsPath}/legault/legault", normal, normal);
@include font-face("Legault", "#{$fontsPath}/legault/legaultb", bold, normal);
@include font-face("Legault", "#{$fontsPath}/legault/legaulti", normal, italic);
@include font-face("Legault", "#{$fontsPath}/legault/legaultz", bold, italic);

//Lobster
@include font-face("Lobster", "#{$fontsPath}/lobster/lobster-regular", normal, normal);

//Lobster Two
@include font-face("Lobster Two", "#{$fontsPath}/lobsterTwo/LobsterTwo-Regular", normal, normal);
@include font-face("Lobster Two", "#{$fontsPath}/lobsterTwo/LobsterTwo-Bold", bold, normal);
@include font-face("Lobster Two", "#{$fontsPath}/lobsterTwo/LobsterTwo-BoldItalic", bold, italic);
@include font-face("Lobster Two", "#{$fontsPath}/lobsterTwo/LobsterTwo-Italic", normal, italic);

//Microgramma
@include font-face("Microgramma", "#{$fontsPath}/microgramma/microgramma", normal, normal);
@include font-face("Microgramma", "#{$fontsPath}/microgramma/microgrammab", bold, normal);
@include font-face("Microgramma", "#{$fontsPath}/microgramma/microgrammai", normal, italic);
@include font-face("Microgramma", "#{$fontsPath}/microgramma/microgrammaz", bold, italic);

//Open Sans Condensed
@include font-face("Open Sans Condensed", "#{$fontsPath}/open_sans/Open_Sans_Condensed", normal, normal);

//Pacifico
@include font-face("Pacifico", "#{$fontsPath}/pacifico/pacifico", normal, normal);

//Quicksand Book
@include font-face("QuicksandBook", "#{$fontsPath}/quicksand_book/quicksand_book", normal, normal);

//Quicksand Dash
@include font-face("QuicksandDash", "#{$fontsPath}/quicksand_dash/quicksand_dash", normal, normal);

//Quicksand Light
@include font-face("QuicksandLight", "#{$fontsPath}/quicksand_light/quicksand_light", normal, normal);

//Raleway
@include font-face("Raleway", "#{$fontsPath}/raleway/Raleway", normal, normal);
@include font-face("Raleway", "#{$fontsPath}/raleway/Raleway-Thin", 100, normal);
@include font-face("Raleway", "#{$fontsPath}/raleway/Raleway-ExtraLight", 200, normal);
@include font-face("Raleway", "#{$fontsPath}/raleway/Raleway-Light", 300, normal);

//Roboto
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-Regular", normal, normal);
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-Thin", 100, normal);
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-ThinItalic", 100, italic);
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-Light", 200, normal);
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-LightItalic", 200, italic);
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-Regular", 300, normal);
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-Medium", 400, normal);
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-MediumItalic", 400, italic);
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-Bold", 500, normal);
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-BoldItalic", 500, italic);
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-Black", 600, normal);
@include font-face("Roboto", "#{$fontsPath}/roboto/Roboto-BlackItalic", 600, italic);

//Roboto Condensed
@include font-face("Roboto Condensed", "#{$fontsPath}/robotoCondensed/RobotoCondensed-Regular", normal, normal);
@include font-face("Roboto Condensed", "#{$fontsPath}/robotoCondensed/RobotoCondensed-Italic", normal, italic);
@include font-face("Roboto Condensed", "#{$fontsPath}/robotoCondensed/RobotoCondensed-Light", 200, normal);
@include font-face("Roboto Condensed", "#{$fontsPath}/robotoCondensed/RobotoCondensed-Regular", 300, normal);
@include font-face("Roboto Condensed", "#{$fontsPath}/robotoCondensed/RobotoCondensed-Bold", 400, normal);

//Roboto Slab
@font-face {
  font-family: 'Roboto Slab';
  src: url('#{$fontsPath}/robotoSlab/RobotoSlab-VariableFont_wght.woff2') format('woff2');
  font-weight: 100 900;
  font-display: swap;
}

//Shelley
@include font-face("Shelley", "#{$fontsPath}/shelley/shelley", normal, normal);
@include font-face("Shelley", "#{$fontsPath}/shelley/shelleyb", bold, normal);
@include font-face("Shelley", "#{$fontsPath}/shelley/shelleyi", normal, italic);
@include font-face("Shelley", "#{$fontsPath}/shelley/shelleyz", bold, italic);

//Slabo
@include font-face("Slabo", "#{$fontsPath}/slabo/slabo-regular", normal, normal);

//Titillium Web
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-Regular", normal, normal);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-Bold", bold, normal);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-Italic", normal, italic);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-ExtraLight", 200, normal);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-ExtraLightItalic", 200, italic);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-Light", 300, normal);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-LightItalic", 300, italic);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-Regular", 400, normal);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-SemiBold", 600, normal);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-SemiBoldItalic", 600, italic);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-Bold", 700, normal);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-BoldItalic", 700, italic);
@include font-face("Titillium Web", "#{$fontsPath}/titilliumWeb/TitilliumWeb-Black", 900, normal);

//Ubuntu
@include font-face("Ubuntu", "#{$fontsPath}/ubuntu/ubuntu", normal, normal);