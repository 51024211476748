@import "backgroundArea";
@import "box";
@import "circle";
@import "contentModule";
@import "formObject";
@import "galleryObject";
@import "googleMap";
@import "htmlScriptObject";
@import "iframeObject";
@import "imageObject";
@import "languageChangeObject";
@import "logoObject";
@import "menuObject";
@import "placeObject";
@import "sliderObject";
@import "socialMediaObject";
@import "textObject";
@import "videoObject";

.HeaderLogoImageObject, .LogoImageObject {
  .hundredPercent {
    height: 100%;
    width: 100%;
  }
}