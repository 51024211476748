.GoogleMap {
  display: flex;
}

.map-over {
  background: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.map,
.GoogleMap {
  iframe {
    border: medium none;
  }
}