.Circle {
  --circle-margin: 5.0625rem;

  margin-top: .625rem;
  margin-left: var(--circle-margin);
  margin-right: var(--circle-margin);

  width: calc(100vw - (var(--circle-margin) * 2)) !important;
  height: calc(100vw - (var(--circle-margin) * 2)) !important;
  top: unset !important;
  left: unset !important;

  .childWrapper {
    display: flex;
  }

}