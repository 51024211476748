.GalleryObject {
  --gallery-margin: 1.40625rem;

  margin-top: .625rem;
  margin-left: var(--gallery-margin);
  margin-right: var(--gallery-margin);

  width: calc(100vw - (var(--gallery-margin) * 2)) !important;
  height: unset !important;

  top: unset !important;
  left: unset !important;

  display: flex;
  justify-content: center;

  .gallery {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr)) !important;

    @media screen and (min-width: 750px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(3, minmax(150px, 1fr)) !important;
    }

    max-width: calc(3 * 300px + 20px);
    grid-gap: 10px;

    a {
      max-width: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin: 0 !important;
    }

  }
}