.FormObject,
.ContactFormObject {
  width: auto !important;
  max-width: 100vw;
  height: 100% !important;
  top: unset !important;
  left: unset !important;

  input[type="file"] {
    font-size: .6rem !important;
  }
}

