////
/// fraction slider
/// @group fraction slider
////

//Parent Node in Preview
.SliderObject {
  .fraction-slider,
  #fraction-slider {
    /** CONTROLS **/
    .prev {
      $svgColor: 'FFFFFF';

      left: .1rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 37.5 37.5' transform='rotate(180)' %3E%3Cpolyline points='12.5,9.4 25,18.8 12.5,28.1' stroke='%23#{$svgColor}' stroke-width='2' fill='none' /%3E%3C/svg%3E");
    }

    .next {
      $svgColor: 'FFFFFF';

      right: .1rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 37.5 37.5' %3E%3Cpolyline points='12.5,9.4 25,18.8 12.5,28.1' stroke='%23#{$svgColor}' stroke-width='2' fill='none' /%3E%3C/svg%3E");
    }

    .prev, .next, .fs-pager-wrapper {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .prev, .next {
      --slider-buttons-z-index: 15000;

      top: 45%;

      display: block;
      position: absolute;
      width: 2.813rem;
      height: 2.813rem;
      z-index: var(--slider-buttons-z-index);
      background-repeat: no-repeat;
      background-color: rgba(0, 0, 0, .25);

      &:link, &:active {
        color: white;
        text-decoration: none;
        line-height: 2rem;
        font-size: xxx-large;
      }
    }

    /** PAGER **/
    .fs-pager-wrapper {
      --pager-wrapper-z-index: 10000;

      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 1rem;
      position: absolute;
      width: 100%;
      bottom: 1.875rem;
      z-index: var(--pager-wrapper-z-index);

      a {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        background-color: rgba(255, 255, 255, 0.5);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
      }

      .active {
        background-color: rgba(255, 255, 255, 0.85);
      }
    }
  }

  //First Child in Preview
  .slider {
    --text-color: #FFF;

    position: absolute;
    width: 100%;
    height: 100%;
  }

  //Only in DCMPRO EDITOR
  .fraction-slider {
    position: relative;
    width: 100%;
    height: 100% !important;
    display: flex;
    overflow: visible;

    &:hover {
      .prev, .next, .fs-pager-wrapper {
        opacity: 1;
      }
    }
  }

  .slide {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5000;

    &.slide-text {
      position: absolute;
      top: 2rem;
      left: auto;
      background-color: white;
      padding: 1rem;
    }

    &.active-slide {
      z-index: 9999;
    }

    .fs_obj {
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      z-index: 7000;

      * {
        display: inline-block;
        position: relative;
        top: 0;
        left: 0;
      }

      picture {
        object-fit: cover;
        object-position: center center;
        height: 100%;
        width: 100%;
        display: block;

        source {
          object-fit: cover;
          object-position: center center;
        }

        img {
          object-fit: cover;
          object-position: center center;
          height: 100%;
          width: 100%;
        }
      }

      p {
        display: block;
      }

      &.imageLayer {
        height: 100%;
      }

      &.fs_fixed_obj {
        z-index: 0;
        left: 0;
      }
    }
  }
}