.SocialMediaObject {
  margin-right: 0 !important;
  overflow: visible !important;

  a {
    img {
      width: calc((var(--icon-width)) * 1px) !important;
    }
  }

  [id^='socialmedia-'] {
    display: flex;
    align-items: center;
  }

  .social-media-icons {
    display: flex;

    &.horizontal {
      flex-direction: row;
    }

    &.vertical {
      flex-direction: column;
    }

    a {
      margin-right: 0 !important;

      img {
        width: calc((var(--icon-width)) * 1px) !important;
      }

      &:hover {
        opacity: 1;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}


