.image {
  &.positioned {
    .image-title, .video-title, .image-src {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      text-align: center;
      padding: 0.25em;
      background: var(--black);
      color: var(--nearly-white);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .image-title {
      display: none; // temporarily
      top: -2em;
      transition: top 0.5s var(--common-transition-curve);
    }

    .image-src {
      bottom: -2em;
      transition: bottom 0.5s var(--common-transition-curve);
    }

    &:hover {
      .video-title {
        top: 0;
      }

      .video-src {
        bottom: 0
      }
    }

    .image-dimensions {
      position: absolute;
      top: 50%;
      left: 50%;
      border: 1px dashed var(--nearly-grey);
    }

    &.with-thumbnail {
      background-size: contain;
      background: var(--black) no-repeat 50%;

      &::before {
        text-shadow: 0 0 10px var(--black);
      }
    }

    &.handle {
      position: absolute;
      width: 200px;
      height: 200px;
      background-color: red;
      left: 30%;
      top: -20px;
    }
  }
}

.ImageObject {
  min-height: 2rem;
  min-width: 2rem;

  .hundredPercent {
    height: 100%;
    width: 100%;
  }

  a {
    display: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;

    &.non-link {
      cursor: default;
    }

    &:nth-of-type(1) {
      display: block;
    }
  }

  a.scaleZoom, a.defaultZoom {
    .layer1, .layer2, .layer3 {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .layer1 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0 !important;
  }

  .layer3 {
    picture {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }

  img {
    &.loading {
      filter: blur(10px);
      transition: filter 0.5s ease;
    }

    &.loaded {
      filter: blur(0);
    }
  }
}

