@import 'utilities/variables/fontVariables';
@import 'utilities/colors/globalColors';
@import 'utilities/variables/global-z-indices';
@import "utilities/functions/functions";

/// @todo Documentation needed
/// @todo * selector is not recommend as it is really slow
* {
  box-sizing: border-box;

  ::before, ::after {
    box-sizing: border-box;
  }
}

/// Assigns the $text-font to body, keygen, select and button
body, keygen, select, button {
  font-family: var(--default-font), sans-serif;
}


// for the data protection checkbox that has to be below all contact forms
#data-protection-checkbox {
  margin-bottom: .8125rem;
  display: inline-flex;
  background-color: rgba(0, 0, 0, 0) !important;
}

a {
  &.hovered {
    text-decoration: underline;
  }
}

.auto-height {
  height: auto !important;
}

.auto-width {
  width: auto !important;
}

.right-fixed {
  position: fixed !important;
  right: .625rem !important;
  left: initial !important;
}

.left-fixed {
  position: fixed !important;
  left: .625rem !important;
}


.dcmp-full-width {
  --breakpoint-full-width: 1201px;

  left: 0 !important;

  width: 100% !important;

  > .frameConsentLayer {
    margin-left: 18vw;
    margin-right: 18vw;
    position: relative;
  }
}

.dcmp-full-screen {
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  box-sizing: border-box;
  object-fit: contain;
}

/* provide downward compatibility */
.auto_height {
  height: auto !important;
}

.right_fixed {
  position: fixed !important;
  right: .625rem !important;
  left: initial !important;
}

.left_fixed {
  position: fixed !important;
  left: 20px !important;
}

.dcmp_full_height {
  height: 100% !important;
}

.dcmp_full_width {
  width: 100% !important;
  left: 0 !important;
}

.dcmp_full_screen {
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  box-sizing: border-box;
  object-fit: contain;
}

/* end of downward compatibility */

.positioned.text:hover {
  outline: 1px dashed #777;
}


.super-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $super-overlay;
}

#loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .9); /* white background with slight transparency */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-loading-overlay);
  font-size: xxx-large;
  opacity: 1;
  transition: opacity .5s ease-in-out;

  span {
    font-size: xxx-large;
    animation: spin 2s linear infinite;
  }
}

/* sorry, dont know where to put */
.cke_editable {
  outline: 1px dotted $aqua;
}

// ------------- pippte story -----------------------------------
.screenShot {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
  z-index: var(--screenshot-z-index);
  background-color: $white;
}

#screenShotMagnifier {
  width: 50px;
  height: 50px;
  background-color: $blue;
  position: absolute;
  z-index: 10000000;
}

#screenShotCanvas {
  cursor: zoom-in;
}

///-------------the video in background story---------------------

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}

@media (max-width: 767px) {
  .fullscreen-bg__video {
    display: none;
  }
}

#dcmp-select-overlay {
  background-color: green;

  p {
    margin: 0 auto;
  }
}

.linked-svg {
  display: none;
}

