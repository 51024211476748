.Box {
  border-top-color: var(--borderTopColor);
  border-left-color: var(--borderLeftColor);
  border-right-color: var(--borderRightColor);
  border-bottom-color: var(--borderBottomColor);
  background-image: var(--background-image-url) !important;
  background-color: var(--background-color);

  &.background-image-type {
    position: absolute !important;
    background-image: var(--background-image-url) !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}