header {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 5000;
  margin-bottom: 20px;

  .HeaderLogoImageObject {
    /// The width and height of the burger button
    $burgerSize: 46px;
    /// The margin for the burger on each side
    $burgerMargin: 10px;

    $burgerCalc: calc(100% - ($burgerSize + (3 * $burgerMargin)));
    max-width: $burgerCalc !important;

    &.quadratic, &.vertical {
      max-width: calc($burgerCalc - 50%) !important;
    }

    &.vertical {
      min-height: calc((3 * $burgerSize) + (2 * $burgerMargin));
    }

    div, img {
      position: relative !important;
    }
  }

  #header-childwrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 20px;
    left: 0;
    top: 0;

    & > *:not(#primary-logo):not(.ImageObject):not(.TextObject):not(.Box) {
      margin: 0 0 0 0 !important;
      left: 0 !important;
      top: 0 !important;
      min-height: inherit;
    }

    & > #primary-logo .ImageObject .TextObject {
      margin: 10px 0 0 10px;
      left: 0 !important;
      top: 0 !important;
    }
  }
}