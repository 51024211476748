.Circle {
  background-image: var(--background-image-url) !important;
  background-color: var(--background-color);

  &.background-image-type {
    position: absolute !important;
    background-image: var(--background-image-url) !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}