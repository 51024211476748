.video,
.VideoObject {
  .positioned {
    background: #eee;
    overflow: hidden;

    .video-title, .video-src {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      text-align: center;
      padding: 0.25em;
      background: #000;
      color: #eee;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .video-title {
      top: -2em;
      transition: top 0.5s var(--common-transition-curve);
    }

    .video-src {
      bottom: -2em;
      transition: bottom 0.5s var(--common-transition-curve);
    }

    &:hover {
      .video-title {
        top: 0;
      }

      .video-src {
        bottom: 0
      }
    }

    .video-dimensions {
      position: absolute;
      top: 50%;
      left: 50%;
      border: 1px dashed var(--nearly-grey);
    }

    &.with-thumbnail {
      background-size: contain;
      background: var(--black) no-repeat 50%;

      &::before {
        text-shadow: 0 0 10px var(--black);
      }
    }

    video {
      width: 100%;
      height: 100%;
    }

    iframe {
      width: 100%;:
      height: 100%;
    }
  }
}