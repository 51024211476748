.LanguageChange, .LanguageChangeObject {
  ul {
    display: flex;
    width: 100%;
    height: 100%;
    line-height: 0;

    &.horizontal {
      flex-direction: row;
    }

    &.vertical {
      flex-direction: column;
    }

    li {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 105%;
      display: inline-block;
      height: 100%;
      width: 100%;

      &.flag-de {
        &.flag-flat {
          background-image: url(https://cms.dextermedia.de/dcmpro/img/flags/flat/de.webp);
        }
      }

      a {
        display: block;
        height: 100%;
      }
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}