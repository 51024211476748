#footer {
  color: black !important;

  display: flex;
  flex-direction: column;

  height: unset !important;
  text-align: center !important;

  #footer-area {
    height: unset !important;
    margin: 0 !important;
    color: black !important;
    width: 100%;

    &:empty {
      display: none;
    }

    .childWrapper .TextObject a[href^="tel"] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }
  }

  .LogoTextObject {
    top: 0 !important;
    left: 0 !important;
    width: unset !important;
    height: unset !important;
    margin: .625rem 7.344rem 3rem !important;
  }

  .TextObject {
    top: 0 !important;
    left: 0 !important;
    height: unset !important;
    width: 95vw !important;
    margin-top: .625rem !important;
  }

  .Box {
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: unset !important;
    margin-top: .625rem !important;

    .dcmp-full-width {
      height: 100% !important;
    }

    .childWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .Box, .TextObject {
      --box-padding: .5rem;

      width: calc(95vw - (var(--box-padding) * 2)) !important;
      margin-left: var(--box-padding);
      margin-right: var(--box-padding);
      padding-left: var(--box-padding);
      padding-right: var(--box-padding);
    }
  }

  .SocialMediaObject {
    top: 0 !important;
    left: 0 !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100vw !important;
    height: unset !important;

    .social-media-icons {
      &.horizontal {
        justify-content: center;
      }
    }
  }

  .dextermedia_logo {
    left: unset !important;
    top: unset !important;
  }

  .HtmlScriptObject {
    left: unset !important;
    top: unset !important;
  }

  .ImageObject {
    top: unset !important;
    left: unset !important;
  }

  .MenuItemsObject {
    display: none;
  }
}