/**
 * External customer stylesheets
 */

@forward "variables/serverVariables";
@use "cookieConsent/cookieConsent";
@use "PageObjects/PageObjects";
@use "consentLayer";
@use "website-style";
@use "custom-mobile/custom-mobile";
@import "animate.css/animate.compat.css";

body {
  color: var(--base-text-color) !important;

  #fullscreen-area {
    height: 100vh !important;
    width: 100%;
    position: fixed;
  }
}